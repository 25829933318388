import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import GapAssessmentForm from "./GapAssessmentForm";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import SearchIcon from "@mui/icons-material/Search";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import { Formik } from "formik";
import ProjectLogForm from "./ProjectLogForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const ProjectLog: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const [gapAssessmentDto, setGapAssessmentDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const handleCloseFeedbackFormModal = () => setOpenFeedbackFormModal(false);
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");
  const [url, setUrl] = useState("/candidate");

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");

  const columns: GridColDef[] = [
    {
      field: "candidateName",
      headerName: "Nome",
      flex: 3,
      editable: false,
      renderCell: (params) => (
        <a
          onClick={() => {
            navigate(`/assessment-admin/project-log/details/${params.id}`, {
              state: { candidateName: params.row.candidateName },
            });
          }}
          style={{
            textDecoration: "none",
            color: "#ec1c24", // Optional: Adjust color for visibility
          }}
        >
          {params.row.candidateName}
        </a>
      ),
    },
    {
      field: "companyFoundationYear",
      headerName: "Ano de Fundação",
      flex: 2,
      editable: false,
    },
    {
      field: "category",
      headerName: "Categoria",
      flex: 2,
      editable: false,
    },
    {
      field: "province",
      headerName: "Província",
      flex: 2,
      editable: false,
    },

    {
      field: "district",
      headerName: "Distrito",
      flex: 2,
      editable: false,
    },
    {
      field: "companyType",
      headerName: "Tipo de Empresa",
      flex: 2,
      editable: false,
    },
    {
      field: "numberOfEmployees",
      headerName: "Número de Funcionários",
      flex: 1.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/assessment-admin/project-log/details/${params.id}`, {
              state: { candidateName: params.row.candidateName },
            });
          }}
        >
          Seleccionar
        </Button>,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      if (selectedName != "" || selectedName.length > 0) {
        values["candidateName"] = selectedName;
      }
      if (selectedSector != "" || selectedSector.length > 0) {
        values["activitySector"] = selectedSector;
      }
      if (selectedProvince != "" || selectedProvince.length > 0) {
        values["province"] = selectedProvince;
      }
      console.log(values);
      let q = "";

      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      console.log("query", q);
      setUrl(
        q.length > 0
          ? "/candidate/search-term/q?" + q.slice(0, -1)
          : "/candidate"
      );
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);

        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  const handleSectorChange = (event: any) => {
    console.log(event.target.value);

    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    console.log(event.target.value);

    setSelectedProvince(event.target.value);
  };
  const handleNameChange = (event: any) => {
    console.log(event.target.value);

    setSelectedName(event.target.value);
  };
  return (
    <React.Fragment>
      <OlogaPageHeader title={"Log de Projectos"}></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield
                      name="candidateName"
                      label="Nome do Candidato"
                      // onChange={handleNameChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Sector de Actividade</InputLabel>
                      <Select
                        value={selectedSector}
                        onChange={handleSectorChange}
                        label="Sector de Actividade"
                      >
                        <MenuItem value="">
                          <em>Selecione um setor</em>
                        </MenuItem>
                        {sectors.map((sector) => (
                          <MenuItem key={sector.value} value={sector.text}>
                            {sector.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Província</InputLabel>
                      <Select
                        value={selectedProvince}
                        onChange={handleProvinceChange}
                        label="Província"
                      >
                        <MenuItem value="">
                          <em>Selecione uma província</em>
                        </MenuItem>
                        {dropdown_province.map((province) => (
                          <MenuItem key={province.value} value={province.text}>
                            {province.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                    {/* <Button
                      size="small"
                      variant="outlined"
                      startIcon={<VisibilityOutlinedIcon />}
                      onClick={() => {
                        // setCandidate({ ...params.row, candidateId: params.id });
                        handleOpenFeedbackFormModal();
                      }}
                    >
                      Atribuir Log
                    </Button> */}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProjectLog;
