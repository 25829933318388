import {
  Logout,
  ProductionQuantityLimits,
  AccountBalance,
  Addchart,
} from "@mui/icons-material";
import {
  PieChart,
  Calendar,
  CreditCard,
  Layers,
  Gift,
  Users,
  Settings,
  LogOut,
} from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";
import GroupsIcon from "@mui/icons-material/Groups";
import RuleIcon from "@mui/icons-material/Rule";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

const allRoles = [
  "ROLE_ADMIN",
  "ROLE_OPERATOR",
  "ROLE_CANDIDATE",
  "ROLE_VENDOR_SUPERVISOR",
  "ROLE_MERCHANT",
  "ROLE_MERCHANT_REORDER",
];

const coreRoles = ["ROLE_ADMIN", "ROLE_OPERATOR"];
const vendorTeamRoles = ["ROLE_VENDOR", "ROLE_VENDOR_SUPERVISOR"];

const pagesSection = [
  {
    href: "/dashboard",
    icon: MenuIcon,
    title: "Menu Principal",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
  },
  {
    href: "/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
    roles: ["ROLE_CANDIDATE"],
  },
  {
    href: "/assessment-admin",
    icon: GroupsIcon,
    title: "Beneficiários e Demandas",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/supply-mapping",
        title: "Mapeamento de Oferta",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/demand-mapping",
        title: "Mapeamento de Demanda",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: RuleIcon,
    title: "Qualificação e Selecção",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/search",
        title: "Ferramenta de Pré-selecção de Beneficiários",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/candidates/create",
        title: "Avaliação de Risco",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/qualification",
        title: "Listagem dos Beneficiarios Pré-Seleccionados",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: AssistantPhotoIcon,
    title: "Selecção da Assistência",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/tool/matchmaking",
        title: "Matchmaking",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/gap-assessment",
        title: "Análise de Lacunas",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/plan-candidate",
        title: "Plano de Acção",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/project-log",
        title: "Log de Projectos",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/assessment-admin",
    icon: ChecklistRtlIcon,
    title: "Execução da Assistência",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/assessment-admin/log-beneficiary",
        title: "Log de Riscos Beneficiario",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/assessment-admin/log-oss",
        title: "Log de Riscos OSS",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/Indicators/",
    icon: Addchart,
    title: "Monitoria e Reporte",
    roles: ["ROLE_ADMIN"],
    children: [
      {
        href: "/assessment-admin/complaintlist",
        title: "Lista de Reclamação",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/Indicators/view",
        title: "Ver Indicadores",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/Indicators/search",
        title: "Definir Indicadores",
        roles: ["ROLE_ADMIN", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/users",
    icon: Users,
    title: "Utilizadores",
    roles: ["ROLE_ADMIN", "ROLE_OPERATOR"],
    children: [
      {
        href: "/users/add-user",
        title: "Adicionar Novo",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
      {
        href: "/users",
        title: "Pesquisar",
        roles: ["ROLE_ADMIN", "ROLE_OPERATOR", ...vendorTeamRoles],
      },
    ],
  },
  {
    href: "/settings/search",
    icon: Settings,
    title: "Configurações",
    roles: ["ROLE_ADMIN"],
  },
  {
    href: "/logout",
    icon: Logout,
    title: "Sair",
    roles: [...allRoles],
  },
] as SidebarItemsType[];

const filterPagesByRole = (role: string) => {
  const userMenu = pagesSection.filter((p: SidebarItemsType) =>
    p.roles?.includes(role)
  );
  userMenu.forEach((menu: SidebarItemsType) => {
    menu.children = menu.children?.filter((ch) => ch.roles?.includes(role));
  });

  return userMenu;
};

const getNavItems = (role: string) => {
  return [
    {
      title: "",
      pages: role ? filterPagesByRole(role) : pagesSection,
    },
  ];
};

export default getNavItems;
