import { is } from "date-fns/locale";
import * as Yup from "yup";

const isRequiredString = Yup.string().required("Required");
const isRequiredNumber = Yup.number()
  .required("Required")
  .positive("Rate must be positive");
const isValidEmail = Yup.string().email("Invalid Email").nullable();

const venueSchema = Yup.object().shape({
  name: isRequiredString,
  countryId: isRequiredString,
  cityId: isRequiredString,
});

const stationSchema = Yup.object().shape({
  name: isRequiredString,
  vendorId: isRequiredString,
  eventId: isRequiredString,
});

const userSchema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").max(100),
  firstName: isRequiredString,
  lastName: isRequiredString,
  username: Yup.string().max(64).required("Required"),
  roleCode: isRequiredString,
  // companyId: isRequiredString,
});
const candidateUserSchema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").max(100),
  firstName: isRequiredString,
  lastName: isRequiredString,
  username: Yup.string().max(64).required("Required"),
  // roleCode: isRequiredString,
  // companyId: isRequiredString,
});
const passwordRules = Yup.object().shape({
  password: Yup.string().min(6).max(32).required("Required"),
  confirmPassword: Yup.string()
    .transform((x) => (x === "" ? undefined : x))
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const eventSchema = Yup.object().shape({
  name: isRequiredString,
  countryId: isRequiredString,
  venueId: isRequiredString,
  startDate: isRequiredString,
  startTime: isRequiredString,
  endDate: isRequiredString,
  endTime: isRequiredString,
  promoterId: isRequiredString,
  expectedAudience: Yup.number()
    .integer()
    .typeError("Please enter a valid number")
    .required("Required"),
});

const merchantAllocationSchema = Yup.object().shape({
  vendorId: isRequiredString,
  eventId: isRequiredString,
  merchantId: isRequiredString,
  stationId: isRequiredString,
});

const companySchema = Yup.object({
  name: isRequiredString,
  countryId: isRequiredString,
  phoneNumber: isRequiredString,
  email: isValidEmail.concat(isRequiredString),
  representativeEmail: isValidEmail,
});

const assessmentSchema = Yup.object().shape({
  name: isRequiredString,
});

const toolSchema = Yup.object().shape({
  id: isRequiredString,
  name: isRequiredString,
  description: isRequiredString,
});
const question = Yup.object().shape({
  name: isRequiredString,
  description: isRequiredString,
  category_id: isRequiredString,
  type: isRequiredString,
});

const worldTourSchema = Yup.object().shape({
  name: isRequiredString,
  artist: isRequiredString,
  startDate: isRequiredString,
  endDate: isRequiredString,
});

const countryTourSchema = Yup.object().shape({
  name: isRequiredString,
  countryId: isRequiredString,
  startDate: isRequiredString,
  endDate: isRequiredString,
});

const commissionSchema = Yup.object().shape({
  eventId: isRequiredString,
  vendorId: isRequiredString,
  rate: isRequiredNumber,
});

export {
  venueSchema,
  stationSchema,
  userSchema,
  passwordRules,
  eventSchema,
  merchantAllocationSchema,
  companySchema,
  worldTourSchema,
  countryTourSchema,
  commissionSchema,
  assessmentSchema,
  toolSchema,
  question,
  candidateUserSchema,
};
