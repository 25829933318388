import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";

interface DemandMappingFormValues {
  id: number;
  responsable: string;
  status: string;
  candidateId: number;
  reference: string;
  description: string;
  name: string;
  activitySector: string;
  subActivitySector: string;
  province: string;
  district: string;
  location: string;
  executionPhase: string;
  perpectiveDuration: string;
  pubDate: string;
  startDate: string;
  endDate: string;
}
interface dropdownDTO {
  text: string;
  value: number;
}
const DemandMappingForm: React.FC<{
  onClose: () => void;
  dto: any;
}> = ({ onClose, dto }) => {
  const [initialValues, setInitialValues] = useState<DemandMappingFormValues>({
    id: 0,
    responsable: "",
    startDate: "",
    endDate: "",
    status: "",
    reference: "",
    description: "",
    name: "",
    activitySector: "",
    subActivitySector: "",
    province: "",
    district: "",
    location: "",
    executionPhase: "",
    perpectiveDuration: "",
    pubDate: "",
    candidateId: dto.candidateId,
  });
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (dto.id != null) {
        try {
          const response = await http.get(`/demand/${dto.id}`);
          if (response.data) {
            setInitialValues(response.data);
          }
        } catch (error) {
          console.error("Error fetching Demand:", error);
        }
      }
      const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
      setDropdown_province(response.data);
      const ACTIVITY_SECTOR = await http.get(
        `simple-entity/findDropdown/ACTIVITY_SECTOR`
      );
      setDropdown_activitySector(ACTIVITY_SECTOR.data);
    };
    fetchData();
  }, [dto]);
  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    setInitialValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleSubmit = async (
    values: DemandMappingFormValues,
    actions: FormikHelpers<DemandMappingFormValues>
  ) => {
    try {
      console.log(values);
      const data = { ...values };
      const response = await http.post("/demand", data);
      if (response.status === 200)
        toast.success("Operação realizada com sucesso");
      //   }
      onClose(); // Fechar o modal após salvar
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    responsable: Yup.string().required("Responsable is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <OlogaTextfield name="name" label="Nome do Projeto" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="subActivitySector-label">
                            Sub-Sector de Actividade
                          </InputLabel>
                          <Select
                            labelId="subActivitySector-label"
                            value={initialValues.subActivitySector}
                            label="Sub-Sector de Actividade"
                            onChange={handleSelectChange("subActivitySector")}
                          >
                            {dropdown_activitySector.map(
                              (subActivitySector) => (
                                <MenuItem
                                  key={subActivitySector.text}
                                  value={subActivitySector.text}
                                >
                                  {subActivitySector.text}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {errors.subActivitySector && (
                            <FormHelperText error>
                              Campo obrigatório
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="province-label">Província</InputLabel>
                          <Select
                            labelId="province-label"
                            value={initialValues.province}
                            label="Província"
                            onChange={handleSelectChange("province")}
                            // required
                            //   error={errors.province}
                          >
                            {dropdown_province.map((province) => (
                              <MenuItem
                                key={province.text}
                                value={province.text}
                              >
                                {province.text}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.province && (
                            <FormHelperText error>
                              Campo obrigatório
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaTextfield name="district" label="Distrito" />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaTextfield name="location" label="Localização" />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaTextfield
                          name="executionPhase"
                          label="Fase de Execução"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaTextfield
                          name="responsable"
                          label="Responsável"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaTextfield
                          name="perpectiveDuration"
                          label="Duração (Meses)"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="startDate"
                          label="Data de Início"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.startDate}
                          onChange={handleChange}
                          error={touched.startDate && Boolean(errors.startDate)}
                          helperText={<ErrorMessage name="startDate" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="endDate"
                          label="Data de Fim"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={values.endDate}
                          onChange={handleChange}
                          error={touched.endDate && Boolean(errors.endDate)}
                          helperText={<ErrorMessage name="endDate" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {values.id === 0 ? "Salvar" : "Atualizar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default DemandMappingForm;
