import React from "react";
import { Button, Box, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ActionPlanIcon,
  DemandIcon,
  FormCandidateIcon,
  GapAssessmentIcon,
  IssuesOssIcon,
  LegalComplianceIcon,
  ListSelectionIcon,
  MappingPartnerIcon,
  MatchmakingIcon,
  PrioritizationBenefIcon,
  ProjectsLogsIcon,
  RiskEvaluationIcon,
  SelectionIcon,
  SupplyIcon,
} from "../../components/Icons";
const AdminFeaturePage: React.FC = () => {
  const navigate = useNavigate();

  const handlePreviousPage = () => {};

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Divider>CAPTAÇÃO DE BENEFICIÁRIOS E DEMANDA</Divider>
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/candidates/info-create")}
              startIcon={<FormCandidateIcon />}
            >
              Questionário de Recolha de Informação do Candidato
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/supply-mapping")}
              startIcon={<SupplyIcon />}
            >
              Ferramenta para o Mapeamento da Oferta
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/demand-mapping")}
              startIcon={<DemandIcon />}
            >
              Ferramenta para o Mapeamento da Demanda
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider>
          {"Qualificações e Seleção dos Beneficiários".toUpperCase()}
        </Divider>
        <br />
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/search")}
              startIcon={<SelectionIcon />}
            >
              Ferramenta de Pré-Selecção dos Beneficiários
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<LegalComplianceIcon />}
            >
              Ferramenta de Avaliação Legal e de Compliance
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePreviousPage}
              startIcon={<PrioritizationBenefIcon />}
            >
              Ferramenta de Priorização dos Beneficiários
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/candidates/create")}
              startIcon={<RiskEvaluationIcon />}
            >
              Ferramenta de Avaliação de Risco
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/qualification")}
              startIcon={<ListSelectionIcon />}
            >
              Listagem dos Beneficiários Pré-Seleccionados
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider>
          {"Seleção da Assistência a ser Providenciado aos Beneficiários".toUpperCase()}
        </Divider>
        <br />
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/tool/matchmaking")}
              startIcon={<MatchmakingIcon />}
            >
              Ferramenta para o Matchmaking
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/gap-assessment")}
              startIcon={<GapAssessmentIcon />}
            >
              Ferramenta de Gap Assessment
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/assessment-admin/action-plan")}
              startIcon={<ActionPlanIcon />}
            >
              Plano de Acção
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePreviousPage}
              startIcon={<MappingPartnerIcon />}
            >
              Ferramenta de Mapeamento de Parceiros
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePreviousPage}
              startIcon={<ProjectsLogsIcon />}
            >
              Ferramenta de Log de Projectos
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider>
          {"Execução da Assistência Selecionado aos Beneficiários".toUpperCase()}
        </Divider>
        <br />
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePreviousPage}
              startIcon={<IssuesOssIcon />}
            >
              Ferramenta de Log de Issues e Riscos
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePreviousPage}
              startIcon={<IssuesOssIcon />}
            >
              Ferramenta de Log de Issues e Riscos (OSS)
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdminFeaturePage;
