import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Avatar, Paper } from "@mui/material";
import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <SignInComponent />
    </React.Fragment>
  );
}

export default SignIn;
