import React, { useState, useEffect, ChangeEvent } from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DialogContentText from "@mui/material/DialogContentText";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Card,
  FormHelperText,
  LinearProgress,
  Radio,
  Input,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Tabs,
  Tab,
  TableHead,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { Remove } from "@mui/icons-material";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import BackButton from "../../components/Buttons/BackButton";
import Terms from "./Terms";
import ImageCompressor from "../../hooks/ImageCompressor";
import useAuth from "../../hooks/useAuth";
interface Shareholder {
  id: number;
  name: string;
  participation: string;
  nationality: string;
}

interface dropdownDTO {
  text: string;
  value: number;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface Loan {
  id: number;
  entity: string;
  loanPurpose: string;
  amount: string;
}
interface Certification {
  id: number;
  certificationName: string;
  productDescription: string;
  certificationType: string;
}

interface Executive {
  id: number;
  name: string;
  position: string;
  industryExperienceYears: string;
  industryExperienceDescription: string;
}

interface CandidateManagement {
  id: number;
  hasRegionalRepresentation: string;
  regionalRepresentationDetails: string;
  plansToExpandGeographies: string;
  expansionDetails: string;
  providesServicesOutsideRegions: string;
  serviceOutsideRegionsDetails: string;
  hasHSEProcesses: string;
  hseProcessesDetails: string;
  performsMaintenance: string;
  maintenanceDetails: string;
  hasQualityControlUnit: string;
  qualityControlUnitDetails: string;
  hasTechnicalCompetence: string;
  technicalCompetenceDetails: string;
  hasEthicsCode: string;
  ethicsCodeProof: string;
  ethicsCodeJustification: string;
  hasOrganizationalStructure: string;
  organizationalStructureProof: string;
  organizationalStructureJustification: string;
  hasDefinedProcesses: string;
  definedProcessesJustification: string;
  hasPurchasePlanning: string;
  certificateProductOrService: string;
  loanExternal: string;
  purchasePlanningJustification: string;
  hasStructuredFunctions: string;
  structuredFunctionsJustification: string;
  hasAdequateResources: string;
  adequateResourcesJustification: string;
  hasGenderEquality: string;
  genderEqualityJustification: string;
  hasHighTurnover: string;
  highTurnoverJustification: string;
  taxRegime: string;
  latestFinancialReportDate: string;
  annualTurnover: string;
  financialStatementsAudited: string;
  auditFrequency: string;
  financialStatementsFrequency: string;
  financialStatementsLast3Years: string;
  hasAdequateEquipment: string;
  adequateEquipmentJustification: string;
  hasAdequateSystems: string;
  adequateSystemsJustification: string;
  hasAdequateInfrastructure: string;
  infrastructureImages: string;
  adequateInfrastructureJustification: string;
}

interface Candidate {
  isComplete: boolean;
  biPhoto: string;
  nuitPhoto: string;
  id: number;
  candidateReference: string;
  candidateName: string;
  candidatureStatus: string;
  firstName: string;
  surname: string;
  companyFoundationYear: string;
  personName: string;
  personPrimaryContact: string;
  personAlternateContact: string;
  fax: string;
  primaryEmail: string;
  alternateEmail: string;
  companyWebsite: string;
  province: string;
  district: string;
  physicalAddress: string;
  category: string;
  activitySector: string;
  subActivitySector: string;
  numberOfEmployees: string;
  size: string;
  activitiesDescription: string;
  marketExperienceYears: string;
  companyType: string;
  employeeProportion: string;
  shareholders: Shareholder[];
  executiveTeam: Executive[];
  desiredAssistance: string;
  additionalAssistance?: string;
  assistanceDetails: string;
  assistancePurpose: string;
  requestedInvestmentAmount?: number;
  createsNewJobs: boolean;
  estimatedAnnualJobCreation?: number;
  previouslyBenefited: boolean;
  previousAssistanceDetails?: string;
  additionalAspects?: string;
  hasCommercialCertificate: string;
  hasINSSCertificate: string;
  hasResidenceProof: string;
  hasID: string;
  hasTaxNumber: string;
  hasTaxNumberCompany: string;
  alvara: string;
  incomeStatement: string;
  hasCourtClearanceCertificate: string;
  hasFinanceClearanceCertificate: string;
  hasCriminalRecord: string;
  noDocumentJustification: string;
  thirdPurpose: string;
  secondPurpose: string;
  firstPurpose: string;
  candidateManagement: CandidateManagement;
}

interface FormTypeErrors {
  desiredAssistance: boolean;
}

interface Errors {
  province: boolean;
  primaryEmail: boolean;
}

interface MediaBiPhotoErrors {
  biPhoto: boolean;
}
interface MediaNuitPhotoErrors {
  nuitPhoto: boolean;
}

interface PersonErrors {
  hasID: boolean;
  hasTaxNumber: boolean;
}

interface CampanyErrors {
  hasTaxNumber: boolean;
  hasTaxNumberCompany: boolean;
  hasID: boolean;
}

interface CandidateFormProps {
  id: number;
  userType: string;
}

const CandidateForm: React.FC<CandidateFormProps> = ({ id, userType }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const { user } = useAuth();
  const [showPorposeRow, SetshowPorposeRow] = useState(false);
  const [isSubmissionDialogOpen, setIsSubmissionDialogOpen] = useState(false);
  const openSubmissionSuccessDialog = () => setIsSubmissionDialogOpen(true);
  const closeSubmissionSuccessDialog = () => {
    setIsSubmissionDialogOpen(false);
    if (userType === "" || userType === "ROLE_CANDIDATE") {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }

    toast.success("Operação realizada com sucesso");
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAssistanceDescription, setSelectedAssistanceDescription] =
    useState("");
  const assistanceDetails: any = {
    "Suporte de registro na Plataforma de Fornecedores da MZLNG":
      "Este serviço oferece assistência técnica completa aos beneficiários para realizarem corretamente o processo de registro na plataforma de fornecedores da Mozambique LNG. Inclui orientação detalhada sobre os requisitos necessários, validação das informações fornecidas e acompanhamento contínuo para garantir que os beneficiários sejam reconhecidos como fornecedores oficiais.",
    "Suporte à Formalização de Empresas":
      "Este serviço apoia os beneficiários no processo de formalização das suas empresas, garantindo que todas as etapas legais e administrativas sejam cumpridas. Abrange orientação sobre registo comercial, obtenção de licenças e certificações, ajudando os beneficiários a operar em conformidade com as leis vigentes e aumentar sua credibilidade no mercado.",
    "Coaching e Mentoria":
      "Este serviço oferece acompanhamento personalizado aos beneficiários, ajudando-os a desenvolver competências essenciais para o crescimento pessoal e empresarial. O coaching é direcionado para o alcance de metas específicas, enquanto a mentoria proporciona orientação estratégica e prática, baseada na experiência de profissionais qualificados, capacitando os beneficiários para enfrentarem desafios com confiança.",
    "Suporte ao Desenvolvimento de Projectos":
      "Este serviço apoia os beneficiários na conceção, planeamento e execução dos seus projetos. Inclui definição de objetivos claros, elaboração de estratégias eficazes e acompanhamento técnico durante a implementação. O objetivo é garantir que os projetos sejam viáveis, sustentáveis e alcancem os resultados desejados.",
    "Suporte ao Acesso ao Financiamento":
      "Este serviço auxilia os beneficiários na obtenção de financiamento para os seus negócios. Inclui identificação de oportunidades de crédito, preparação de propostas financeiras robustas, organização da documentação necessária e orientação estratégica para aumentar as chances de aprovação em programas de financiamento.",
    "Suporte Administrativo do Negócio":
      "Este serviço oferece suporte nas operações administrativas diárias das empresas dos beneficiários. Abrange organização de documentos, gestão eficiente de recursos internos e otimização de processos, permitindo que os beneficiários foquem no crescimento e nas atividades principais do negócio.",
    "Formação e Capactiação Online":
      "Este serviço disponibiliza cursos e workshops online através do portal CapaciaMoz. Permite que os beneficiários desenvolvam competências à distância, com flexibilidade de horários e acesso a uma ampla gama de conteúdos educativos atualizados, promovendo aprendizagem contínua e adaptada às suas necessidades.",
  };

  const [steps, setSteps] = useState([
    "Instruções de Preenchimento",
    "Assistência Requerida",
  ]);
  const [selectedBIImage, setSelectedBIImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [selectedNUITImage, setSelectedNUITImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [formtype, setFormType] = useState(5);
  const [finalBtn, SetFinalBtn] = useState(false);
  const navigate = useNavigate();
  const [candidatureStatus, setCandidatureStatus] = useState("");
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [dropdown_quarter_year, setDropdown_quarter_year] = useState<
    dropdownDTO[]
  >([]);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenLoader = () => {
    setLoader(true);
  };
  const handleCloseLoader = () => {
    setLoader(false);
  };
  const handleConfirm = async () => {};
  const [
    dropdown_estimatedAnnualJobCreation,
    setDropdown_estimatedAnnualJobCreation,
  ] = useState<dropdownDTO[]>([]);
  const [dropdown_yes_or_no, setDropdown_yes_or_no] = useState<dropdownDTO[]>(
    []
  );
  const [dropdown_y_or_n, setDropdown_y_or_n] = useState<dropdownDTO[]>([]);
  const [dropdown_desiredAssistance, setDropdown_desiredAssistance] = useState<
    dropdownDTO[]
  >([]);
  const [dropdown_companyType, setDropdown_companyType] = useState<
    dropdownDTO[]
  >([]);
  const [dropdown_marketExperienceYears, setDropdown_marketExperienceYears] =
    useState<dropdownDTO[]>([]);
  const [dropdown_size, setDropdown_size] = useState<dropdownDTO[]>([]);
  const [dropdown_category, setDropdown_category] = useState<dropdownDTO[]>([]);
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeStepTab, setActiveStepTab] = useState<number>(5);
  const [candidate, setCandidate] = useState<Candidate>({
    id: 0,
    isComplete: false,
    candidateReference: "",
    candidateName: "",
    firstName: "",
    surname: "",
    companyFoundationYear: "",
    personName: "",
    personPrimaryContact: "",
    personAlternateContact: "",
    fax: "",
    primaryEmail: "",
    alternateEmail: "",
    companyWebsite: "",
    province: "",
    district: "",
    physicalAddress: "",
    category: "Empresa",
    activitySector: "",
    subActivitySector: "",
    numberOfEmployees: "",
    size: "",
    activitiesDescription: "",
    marketExperienceYears: "",
    companyType: "",
    employeeProportion: "",
    shareholders: [],
    executiveTeam: [],
    candidatureStatus: "",
    desiredAssistance: "",
    additionalAssistance: "",
    assistanceDetails: "",
    assistancePurpose: "",
    requestedInvestmentAmount: 0,
    createsNewJobs: false,
    estimatedAnnualJobCreation: 0,
    previouslyBenefited: false,
    previousAssistanceDetails: "",
    additionalAspects: "",
    hasCommercialCertificate: "",
    hasINSSCertificate: "",
    hasID: "",
    hasResidenceProof: "",
    hasTaxNumber: "",
    hasTaxNumberCompany: "",
    alvara: "",
    incomeStatement: "",
    hasCourtClearanceCertificate: "",
    hasFinanceClearanceCertificate: "",
    hasCriminalRecord: "",
    noDocumentJustification: "",
    biPhoto: "",
    nuitPhoto: "",
    firstPurpose: "",
    secondPurpose: "",
    thirdPurpose: "",
    candidateManagement: {
      id: 0,
      certificateProductOrService: "",
      financialStatementsLast3Years: "",
      hasRegionalRepresentation: "",
      loanExternal: "",
      regionalRepresentationDetails: "",
      plansToExpandGeographies: "",
      expansionDetails: "",
      providesServicesOutsideRegions: "",
      serviceOutsideRegionsDetails: "",
      hasHSEProcesses: "",
      hseProcessesDetails: "",
      performsMaintenance: "",
      maintenanceDetails: "",
      hasQualityControlUnit: "",
      qualityControlUnitDetails: "",
      hasTechnicalCompetence: "",
      technicalCompetenceDetails: "",
      hasEthicsCode: "",
      ethicsCodeProof: "",
      ethicsCodeJustification: "",
      hasOrganizationalStructure: "",
      organizationalStructureProof: "",
      organizationalStructureJustification: "",
      hasDefinedProcesses: "",
      definedProcessesJustification: "",
      hasPurchasePlanning: "",
      purchasePlanningJustification: "",
      hasStructuredFunctions: "",
      structuredFunctionsJustification: "",
      hasAdequateResources: "",
      adequateResourcesJustification: "",
      hasGenderEquality: "",
      genderEqualityJustification: "",
      hasHighTurnover: "",
      highTurnoverJustification: "",
      taxRegime: "",
      latestFinancialReportDate: "",
      annualTurnover: "",
      financialStatementsAudited: "",
      auditFrequency: "",
      financialStatementsFrequency: "",
      hasAdequateEquipment: "",
      adequateEquipmentJustification: "",
      hasAdequateSystems: "",
      adequateSystemsJustification: "",
      hasAdequateInfrastructure: "",
      infrastructureImages: "",
      adequateInfrastructureJustification: "",
    },
  });
  const [mediaBI, setMediaBI] = useState<FormData>();
  const [mediaNUIT, setMediaNUIT] = useState<FormData>();
  const [errors, setErrors] = useState<Errors>({
    province: true,
    primaryEmail: true,
  });
  const [mediaBiPhotoErrors, setMediaBiPhotoErrors] =
    useState<MediaBiPhotoErrors>({
      biPhoto: true,
    });
  const [mediaNuitPhotoErrors, setMediaNuitPhotoErrors] =
    useState<MediaNuitPhotoErrors>({
      nuitPhoto: true,
    });
  const [formTypeErrors, setFormTypeErrors] = useState<FormTypeErrors>({
    desiredAssistance: true,
  });
  const [errorsPerson, setErrorsPerson] = useState<PersonErrors>({
    hasID: true,
    hasTaxNumber: true,
  });
  const [errorsCampany, setErrorsCampany] = useState<CampanyErrors>({
    hasTaxNumberCompany: true,
    hasTaxNumber: true,
    hasID: true,
  });
  function getStepsByAssistanceType(assistanceType: string) {
    let steps = ["Instruções de Preenchimento", "Assistência Requerida"];
    switch (assistanceType) {
      case "Suporte de registro na Plataforma de Fornecedores da MZLNG":
      case "Suporte à Formalização de Empresas":
        steps.push("Informações Gerais do Candidato");
        setFormType(2);
        break;

      case "Coaching e Mentoria":
      case "Suporte ao Acesso ao Financiamento":
      case "Suporte ao Desenvolvimento de Projectos":
      case "Suporte Administrativo do Negócio":
        steps.push("Informações Gerais do Candidato", "Perfil da Empresa");
        setFormType(3);
        break;

      case "Formação e Capactiação Online":
      case "Acesso ao Investimento":
        steps.push(
          "Informações Gerais do Candidato",
          "Perfil da Empresa",
          "Informação Legal",
          "Informação sobre a gestão da empresa do candidato"
        );
        setFormType(5);
        break;

      default:
        console.warn("Assistência desconhecida selecionada.");
        setFormType(5);
        break;
    }

    setSteps(steps);
  }
  function getStepsByCategory(category: string, assistanceType: string) {
    let steps = ["Instruções de Preenchimento", "Assistência Requerida"];
    switch (assistanceType) {
      case "Suporte de registro na Plataforma de Fornecedores da MZLNG":
      case "Suporte à Formalização de Empresas":
        steps.push("Informações Gerais do Candidato");
        setFormType(2);
        break;

      case "Coaching e Mentoria":
      case "Suporte ao Acesso ao Financiamento":
      case "Suporte ao Desenvolvimento de Projectos":
      case "Suporte Administrativo do Negócio":
        steps.push("Informações Gerais do Candidato", "Perfil da Empresa");
        setFormType(3);
        break;

      case "Formação e Capacity-Building TEPMA 1":
      case "Acesso ao Investimento":
        steps.push(
          "Informações Gerais do Candidato",
          "Perfil da Empresa",
          "Informação Legal",
          "Informação sobre a gestão da empresa do candidato"
        );
        setFormType(5);
        break;

      default:
        console.warn("Assistência desconhecida selecionada.");
        setFormType(5);
        break;
    }

    setSteps(steps);
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  const handleChangetab = (event: any, newValue: any) => {
    setActiveStepTab(newValue);
  };
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);

        const CANDIDATE_CATEGORY = await http.get(
          `simple-entity/findDropdown/CANDIDATE_CATEGORY`
        );
        setDropdown_category(CANDIDATE_CATEGORY.data);

        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setDropdown_activitySector(ACTIVITY_SECTOR.data);

        const CANDIDATE_SIZE = await http.get(
          `simple-entity/findDropdown/CANDIDATE_SIZE`
        );
        setDropdown_size(CANDIDATE_SIZE.data);

        const MARKET_TIME = await http.get(
          `simple-entity/findDropdown/MARKET_TIME`
        );
        setDropdown_marketExperienceYears(MARKET_TIME.data);

        const TYPE_OF_COMPANY = await http.get(
          `simple-entity/findDropdown/TYPE_OF_COMPANY`
        );
        setDropdown_companyType(TYPE_OF_COMPANY.data);

        const SUPPORT_TYPE = await http.get(
          `simple-entity/findDropdown/SUPPORT_TYPE`
        );
        setDropdown_desiredAssistance(SUPPORT_TYPE.data);
        var desiredAssistance = SUPPORT_TYPE.data as [];
        const removeAssistence = [
          "Suporte ao Acesso ao Investimento",
          "Suporte Administrativo do Negócio",
          "Suporte ao Acesso ao Financiamento",
          "Suporte ao Desenvolvimento de Projectos",
        ];

        if (user?.roleName === "ROLE_CANDIDATE") {
          const filteredAssistance = desiredAssistance.filter(
            (assistence: dropdownDTO) =>
              !removeAssistence.includes(assistence.text.trim())
          );
          setDropdown_desiredAssistance(filteredAssistance);
        }

        const CREATION_OF_NEW_JOB = await http.get(
          `simple-entity/findDropdown/CREATION_OF_NEW_JOB`
        );
        setDropdown_estimatedAnnualJobCreation(CREATION_OF_NEW_JOB.data);

        const YE_OR_NO = await http.get(`simple-entity/findDropdown/YE_OR_NO`);
        setDropdown_yes_or_no(YE_OR_NO.data);

        const filteredData = YE_OR_NO.data.filter(
          (option: dropdownDTO) => option.text !== "N/A"
        );
        setDropdown_y_or_n(filteredData);

        const QUARTER_YEAR = await http.get(
          `simple-entity/findDropdown/QUARTER_YEAR`
        );
        setDropdown_quarter_year(QUARTER_YEAR.data);
      } catch (error) {
        console.error("Error fetching options", error);
      } finally {
      }
    };
    fetchOptions();
    if (id > 0) {
      const fetchCandidateInfo = async () => {
        const result = await http.get(`/candidate/` + id);
        getStepsByCategory(result.data.category, result.data.desiredAssistance);
        setCandidate(result.data);
        setCandidatureStatus(result.data?.candidatureStatus);
        getStepsByAssistanceType(result.data?.desiredAssistance);

        if (result.data.desiredAssistance === "Coaching e Mentoria") {
          SetshowPorposeRow(false);
        } else {
          SetshowPorposeRow(true);
        }
      };
      fetchCandidateInfo();
    }
  }, []);

  const handleStepChange = (step: number, type: string) => {
    console.log("activeStep", activeStep);
    console.log("progress", progress);
    if (step == 2) {
      const newErrors: FormTypeErrors = {
        desiredAssistance: candidate.desiredAssistance === "",
      };

      if (Object.values(newErrors).some((error) => error)) {
        setFormTypeErrors(newErrors);
        return;
      }
    }
    if (step == 3) {
      const newErrors: Errors = {
        province: candidate.province === "",
        primaryEmail: candidate.primaryEmail === "",
      };

      if (Object.values(newErrors).some((error) => error)) {
        setErrors(newErrors);
        return;
      }
    }

    if (step === 5) {
      const isEmpty = (value: any | null) =>
        value === null ||
        value === undefined ||
        value === "" ||
        value === "null";

      console.log("hasTaxNumber", candidate.hasTaxNumber);
      console.log("hasTaxNumberCompany", candidate.hasTaxNumberCompany);

      if (
        candidate.hasTaxNumber === "Sim" ||
        candidate.hasTaxNumberCompany === "Sim"
      ) {
        const newMediaNuitPhotoErrors: MediaNuitPhotoErrors = {
          nuitPhoto: isEmpty(candidate.nuitPhoto + selectedNUITImage),
        };

        if (Object.values(newMediaNuitPhotoErrors).includes(true)) {
          setMediaNuitPhotoErrors((prevState) => ({
            ...prevState,
            ...newMediaNuitPhotoErrors,
          }));
          return;
        }
      }

      if (candidate.hasID === "Sim") {
        const newMediaBiPhotoErrors: MediaBiPhotoErrors = {
          biPhoto: isEmpty(candidate.biPhoto + selectedNUITImage),
        };

        console.log("newMediaBiPhotoErrors", newMediaBiPhotoErrors);
        console.log(
          "candidate.biPhoto + selectedNUITImage",
          candidate.biPhoto + selectedNUITImage
        );

        if (Object.values(newMediaBiPhotoErrors).includes(true)) {
          setMediaBiPhotoErrors((prevState) => ({
            ...prevState,
            ...newMediaBiPhotoErrors,
          }));
          return;
        }
      }
    }

    //setMediaErrors
    setActiveStep(step);
    setActiveStepTab(0);
  };

  const handleChange = (field: keyof Candidate, value: string | boolean) => {
    setCandidate((prevState) => ({ ...prevState, [field]: value }));
    setErrors({
      ...errors,
      [field]: value === "",
    });
  };
  const handleImageBIUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileBytes = event.target.files?.[0];

    if (fileBytes) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedBIImage(e.target?.result || null);
      };
      reader.readAsDataURL(fileBytes as Blob);
    }

    const formData = await ImageCompressor(
      fileBytes as Blob,
      fileBytes?.name as string
    );

    setMediaBI(formData);
    updateVariable(candidate, "file", formData, null);
  };
  const handleImageNUITUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const fileBytes = event.target.files?.[0];

    if (fileBytes) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedNUITImage(e.target?.result || null);
      };
      reader.readAsDataURL(fileBytes as Blob);
    }

    const formData = await ImageCompressor(
      fileBytes as Blob,
      fileBytes?.name as string
    );

    setMediaNUIT(formData);
    updateVariable(candidate, "file", formData, null);
  };
  const updateVariable = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };
    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      currentObject["updated"] = 1;
      setCandidate({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      currentObject["updated"] = 1;
      setCandidate({ ...currentObject });
    }
  };

  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 25,
          height: 25,
          borderRadius: "50%",
          backgroundColor: active || completed ? "#007680" : "#697b7c",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {icon}
      </div>
    );
  };
  const handleArrayChange = (
    index: number,
    field: string,
    value: string,
    arrayName: "shareholders" | "executiveTeam"
  ) => {
    const updatedArray = [...candidate[arrayName]];
    updatedArray[index] = { ...updatedArray[index], [field]: value };
    setCandidate((prevState) => ({ ...prevState, [arrayName]: updatedArray }));
  };

  const handleAddToArray = (arrayName: "shareholders" | "executiveTeam") => {
    setCandidate((prevState) => {
      if (prevState[arrayName].length >= 3) {
        return prevState;
      }

      const newItem =
        arrayName === "shareholders"
          ? { name: "", shares: "" }
          : arrayName === "executiveTeam"
          ? { name: "", position: "" }
          : arrayName === "certifications"
          ? {
              certificationName: "",
              certificationDate: "",
              certificationIssuer: "",
            }
          : { loanAmount: "", loanTerm: "", interestRate: "" };

      return {
        ...prevState,
        [arrayName]: [...prevState[arrayName], newItem],
      };
    });
  };

  const handleRemoveFromArray = (
    index: number,
    arrayName: "shareholders" | "executiveTeam"
  ) => {
    const array = candidate[arrayName];
    if (arrayName === "shareholders") {
      const updatedArray = (array as Shareholder[]).filter(
        (_, i: number) => i !== index
      );
      setCandidate((prevState) => ({
        ...prevState,
        shareholders: updatedArray,
      }));
    } else if (arrayName === "executiveTeam") {
      const updatedArray = (array as Executive[]).filter(
        (_, i: number) => i !== index
      );
      setCandidate((prevState) => ({
        ...prevState,
        executiveTeam: updatedArray,
      }));
    }
  };

  const handleIncompleteSubmit = async () => {
    try {
      candidate.isComplete = false;
      if (candidate.id <= 0) {
        const result = await http.post(`/candidate`, candidate);
        if (mediaBI) {
          await http.post(
            "media/upload/candidatureBI/" + result.data.id,
            mediaBI
          );
        }
        if (mediaNUIT) {
          await http.post(
            "media/upload/candidatureBUIT/" + result.data.id,
            mediaNUIT
          );
        }
        toast.success("Operação realizada com sucesso");
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const handleSubmit = async () => {
    handleOpenLoader();
    try {
      if (formtype == 5) {
        if (candidate.id <= 0 || candidate.id == null) {
          if (candidate.hasID === "sim") {
            if (mediaBI === undefined || mediaBI === null) {
              toast.error(
                "Carregamento obrigatório: Certifique-se de anexar uma cópia do Bilhete de Identidade (BI) antes de enviar a candidatura."
              );
              handleCloseLoader();
              return;
            }
          }

          if (
            candidate.hasTaxNumber === "Sim" ||
            candidate.hasTaxNumberCompany === "Sim"
          ) {
            if (mediaNUIT === undefined || mediaNUIT === null) {
              toast.error(
                "Carregamento obrigatório: Certifique-se de anexar uma cópia do NUIT antes de enviar a candidatura."
              );
              handleCloseLoader();
              return;
            }
          }
        }
      }
      candidate.isComplete = true;
      if (candidate.id <= 0) {
        console.log("candidate", candidate);
        const result = await http.post(`/candidate`, candidate);
        if (mediaBI) {
          await http.post(
            "media/upload/candidatureBI/" + result.data.id,
            mediaBI
          );
        }
        if (mediaNUIT) {
          await http.post(
            "media/upload/candidatureBUIT/" + result.data.id,
            mediaNUIT
          );
        }
        openSubmissionSuccessDialog();
      } else {
        const result = await http.put(`/candidate/${candidate.id}`, candidate);
        if (mediaBI) {
          await http.post(
            "media/upload/candidatureBI/" + candidate.id,
            mediaBI
          );
        }
        if (mediaNUIT) {
          await http.post(
            "media/upload/candidatureBUIT/" + candidate.id,
            mediaNUIT
          );
        }

        toast.success("Operação realizada com sucesso");
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("error:", error);
      handleCloseLoader();
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    setCandidate((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    if (fieldName === "desiredAssistance") {
      getStepsByAssistanceType(value);

      const selectedAssistance = event.target.value;
      if (assistanceDetails[selectedAssistance]) {
        setSelectedAssistanceDescription(assistanceDetails[selectedAssistance]);
        setIsDialogOpen(true);
      }

      if (value === "Coaching e Mentoria") {
        SetshowPorposeRow(false);
      } else {
        SetshowPorposeRow(true);
      }
    }
  };
  const progress =
    activeStep === 1 ? 0 : (activeStep / (steps.length - 1)) * 100;

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <>
      <Dialog
        open={isSubmissionDialogOpen}
        onClose={closeSubmissionSuccessDialog}
      >
        <DialogTitle>Caro Candidato,</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O seu pedido foi efetuado com sucesso! Por favor, aguarde o nosso
            contacto com a resposta ao seu pedido. Entraremos em contacto
            brevemente com os próximos passos.
          </DialogContentText>
          <DialogContentText>Até breve,</DialogContentText>
          <hr></hr>
          <DialogContentText>
            <b>Equipa CapacitaMoz!</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubmissionSuccessDialog} color="primary">
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ margin: "auto" }}>
        <CardContent>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <OlogaPageHeader title={"Formulário de recolha de informação"} />
              {activeStep > 0 && candidate.id <= 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleIncompleteSubmit}
                  startIcon={<SaveIcon />}
                  style={{
                    minWidth: "300px",
                    display: "flex",
                  }}
                >
                  Guardar Formulário
                </Button>
              )}
            </div>

            <center>
              <Typography
                variant="caption"
                align="center"
                style={{ marginBottom: "8px" }}
              >
                Progresso: {Math.round(progress)}%
              </Typography>
            </center>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ marginBottom: "16px" }}
            />
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={CustomStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>
              {activeStep === 0 &&
                (userType === "" || userType === "ROLE_CANDIDATE" ? (
                  <>
                    <Terms />
                    <Card variant="elevation" sx={{ mb: 3 }}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ fontSize: "1.5rem" }}
                        >
                          Questionário de Recolha de Informação dos Candidatos
                          do One Stop Shop
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{ fontSize: "1.2rem" }}
                        >
                          Caro Candidato,
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{ fontSize: "1.2rem" }}
                        >
                          {/* O questionário deve ser preenchido de forma honesta e
                          completa, pois isso permitirá a correta identificação
                          das necessidades do Candidato. */}
                          <p>
                            Seja bem vindo à Ferramenta de Gestão do
                            CapacitaMoz!
                          </p>
                          <p>
                            {" "}
                            Esta nota tem como objetivo orientá-lo sobre o
                            processo de registo para solicitar assistência para
                            a melhoria da sua empresa. O apoio oferecido será
                            ajustado às necessidades específicas do seu negócio,
                            garantindo um processo eficiente e claro dependente
                            da assistência solicitada.
                          </p>
                          <p>
                            Para o registo, será necessário fornecer a seguinte
                            informação e documentação:
                          </p>
                          <ul>
                            <li>Nome</li>
                            <li>Contacto</li>

                            <li>Dados da Empresa</li>
                            <li>Divisão de Acionistas</li>
                            <li> Dados de até três membros da empresa</li>
                            <li>
                              {" "}
                              NUIT (Número Único de Identificação Tributária)
                            </li>
                            <li> Bilhete de Identidade (BI)</li>
                          </ul>
                          Com esta abordagem, pretendemos assegurar que o
                          processo de seleção e priorização dos Beneficiários
                          seja realizado de forma rigorosa e objetiva,
                          facilitando a prestação de assistência conforme as
                          necessidades identificadas.
                          <p>
                            <strong>Equipa CapacitaMoz! </strong>
                          </p>
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                ) : (
                  <Card variant="elevation">
                    <CardContent>
                      <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ fontSize: "1.5rem" }}
                      >
                        Instruções de Preenchimento
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem" }}
                      >
                        Por forma a garantir o preenchimento assertivo das
                        ferramentas identificadas nas tabs seguintes,
                        dever-se-ão observar os seguintes passos:
                      </Typography>
                      <ul>
                        <li>
                          <Typography>
                            <strong>1.</strong> Primeiramente, deve-se proceder
                            com o preenchimento da Ferramenta T1 (Questionário
                            de Recolha de Informação dos Beneficiários), tendo
                            em conta o tipo de assistência requerida pelo
                            Beneficiário, conforme instruções de preenchimento
                            descritas na respectiva ferramenta.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>2.</strong> De seguida e após recolha de
                            informação do Beneficiário no ponto anterior,
                            dever-se-á transferir os dados de cada questionário
                            preenchido por cada Beneficiário para a ferramenta
                            T16 (Base de Dados Master), onde serão armazenados
                            os dados de todos os Beneficiários. Esta ferramenta
                            será constantemente actualizada, com base no estágio
                            de avaliação e de prestação do serviço a cada um dos
                            Beneficiários.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>3.</strong> Uma vez preenchida a Base de
                            dados Master dos Beneficiários, deve ser igualmente
                            preenchida a T2.1 (Ferramenta de Mapeamento da
                            Oferta), onde são mapeados todos os Beneficiários,
                            com a descrição da sua atividade para efeitos de
                            realização do Matchmaking.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>4.</strong> Posteriormente e sempre que haja
                            uma demanda/oportunidade da TEPMA1, do OSS ou ainda
                            de outras entidades, deverão ser mapeadas na
                            ferramenta T2.2 (Ferramenta de Mapeamento da
                            Demanda).
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>5.</strong> Após a recolha da informação e
                            identificação do tipo de assistência requerida pelos
                            Beneficiários, segue o processo de selecção e
                            priorização dos Beneficiários. Dependendo do Nível
                            de Serviço em que o Beneficiário se encontra,
                            deverão ser preenchidas as seguintes ferramentas:
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 1:</strong> Beneficiários sem qualquer
                            processo de validação e priorização. Estes serão
                            diretamente mapeados na Ferramenta T7 (Ferramenta de
                            Listagem dos Beneficiários Pré-Seleccionados) como
                            sendo Beneficiários elegíveis para receber a(s)
                            assistência(s) requerida(s).
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 2:</strong> Beneficiários alvo de
                            preenchimento da Ferramenta T5 (Ferramenta de
                            Priorização dos Beneficiários). Uma vez priorizados
                            (Beneficiários com Classificação Global Elevada), os
                            Beneficiários serão igualmente listados na
                            Ferramenta T7 (Ferramenta de Listagem dos
                            Beneficiários Pré-Seleccionados), que constitui a
                            lista final dos Beneficiários Seleccionados.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>Nível 3:</strong> Beneficiários alvo de
                            preenchimento das Ferramentas abaixo, atendendo à
                            seguinte sequência:
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>I.</strong> T3 (Ferramenta de Pré-Selecção
                            dos Beneficiários), de acordo com a demanda por
                            projetos existentes por parte da TEPMA1. Somente os
                            Beneficiários com a Classificação Global
                            'Seleccionado', transitam para a fase seguinte de
                            avaliação.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>II.</strong> Seguido do preenchimento da
                            T4.1 (Ferramenta de Avaliação Legal e de Compliance
                            de Empresas) e a T4.2 (Ferramenta de Avaliação Legal
                            e de Compliance de Particulares), voltadas a
                            determinar se as empresas e particulares estão
                            devidamente constituídas e não possuem nenhum
                            cadastro criminal. De referir que só os
                            beneficiários que com base nos critérios
                            estabelecidos forem considerados compliance,
                            passarão para a próxima fase.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            <strong>III.</strong> E finalmente, a T6 (Ferramenta
                            de Avaliação Final dos Beneficiários), onde o
                            Técnico Responsável avalia o perfil final dos
                            Beneficiários para efeitos de decisão final quanto à
                            sua elegibilidade.
                          </Typography>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                ))}
              {activeStep === 1 && (
                <Box p={12}>
                  <Typography variant="h6">Assistência Requerida</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="desiredAssistance-label">
                          Por favor indique a Assistência que deseja obter
                        </InputLabel>
                        <Select
                          labelId="desiredAssistance-label"
                          value={candidate.desiredAssistance}
                          label="Por favor indique a Assistência que deseja obter"
                          onChange={handleSelectChange("desiredAssistance")}
                          required
                          error={formTypeErrors.desiredAssistance}
                        >
                          {dropdown_desiredAssistance.map(
                            (desiredAssistance) => (
                              <MenuItem
                                key={desiredAssistance.text}
                                value={desiredAssistance.text}
                              >
                                {desiredAssistance.text}
                              </MenuItem>
                            )
                          )}
                        </Select>{" "}
                        {formTypeErrors.desiredAssistance && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                      >
                        <DialogTitle>
                          Informações sobre a Assistência
                        </DialogTitle>
                        <DialogContent>
                          <p>{selectedAssistanceDescription}</p>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setIsDialogOpen(false)}
                            color="primary"
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>

                  {showPorposeRow && (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          label="Primeiro Propósito da assistência."
                          value={candidate.firstPurpose}
                          onChange={(e) =>
                            handleChange("firstPurpose", e.target.value)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Segundo Propósito da assistência."
                          value={candidate.secondPurpose}
                          onChange={(e) =>
                            handleChange("secondPurpose", e.target.value)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Terceiro Propósito da assistência."
                          value={candidate.thirdPurpose}
                          onChange={(e) =>
                            handleChange("thirdPurpose", e.target.value)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Caso deseja obter Assistência(s) adicional(ais), por favor especifique."
                        value={candidate.additionalAssistance}
                        onChange={(e) =>
                          handleChange("additionalAssistance", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Por favor, forneça o detalhe da(s) assistência(s) que deseja obter"
                        value={candidate.assistanceDetails}
                        onChange={(e) =>
                          handleChange("assistanceDetails", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Indique por favor a finalidade da(s) Assistência(s) solicitada(s)*"
                        value={candidate.assistancePurpose}
                        onChange={(e) =>
                          handleChange("assistancePurpose", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Caso tenha seleccionado a opção 'Acesso ao Investimento', por favor indique o montante requerido (opcional)"
                        value={candidate.requestedInvestmentAmount}
                        onChange={(e) =>
                          handleChange(
                            "requestedInvestmentAmount",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={candidate.createsNewJobs}
                        onChange={(e) =>
                          handleChange("createsNewJobs", e.target.checked)
                        }
                      />
                    }
                    label="A(s) Assistência(s) solicitada(s) permite a criação novos postos de trabalho?"
                  />
                  {candidate.createsNewJobs && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="estimatedAnnualJobCreation-label">
                        Se sim, por favor, especifique a estimativa anual de
                        criação destes postos (opcional)
                      </InputLabel>
                      <Select
                        labelId="estimatedAnnualJobCreation-label"
                        value={candidate.estimatedAnnualJobCreation}
                        label="Se sim, por favor, especifique a estimativa anual de
                  criação destes postos (opcional)"
                        onChange={handleSelectChange(
                          "estimatedAnnualJobCreation"
                        )}
                      >
                        {dropdown_estimatedAnnualJobCreation.map(
                          (estimatedAnnualJobCreation) => (
                            <MenuItem
                              key={estimatedAnnualJobCreation.text}
                              value={estimatedAnnualJobCreation.text}
                            >
                              {estimatedAnnualJobCreation.text}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={candidate.previouslyBenefited}
                        onChange={(e) =>
                          handleChange("previouslyBenefited", e.target.checked)
                        }
                      />
                    }
                    label="O Candidato já se beneficiou anteriormente deste tipo de Assistências?"
                  />
                  {candidate.previouslyBenefited && (
                    <TextField
                      label="Se sim, por favor, forneça o detalhe da assistência anteriormente prestada (opcional)"
                      value={candidate.previousAssistanceDetails}
                      onChange={(e) =>
                        handleChange(
                          "previousAssistanceDetails",
                          e.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                  )}
                  <TextField
                    label="Por favor, descreva os aspectos adicionais que acredita estar em falta ou que gostaria de obter melhorias na sua empresa (opcional)"
                    value={candidate.additionalAspects}
                    onChange={(e) =>
                      handleChange("additionalAspects", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Box>
              )}
              {activeStep === 2 && (
                <Box p={3}>
                  <Typography variant="h6">
                    Informações Gerais do Candidato
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Nome"
                        value={candidate.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Sobrenome"
                        value={candidate.surname}
                        onChange={(e) =>
                          handleChange("surname", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Ano de Fundação do Candidado (se Aplicável)"
                        type="number"
                        value={candidate.companyFoundationYear}
                        onChange={(e) =>
                          handleChange("companyFoundationYear", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Pessoa de Contacto"
                        value={candidate.personName}
                        onChange={(e) =>
                          handleChange("personName", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Contato Principal"
                        value={candidate.personPrimaryContact}
                        onChange={(e) =>
                          handleChange("personPrimaryContact", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Pessoa de Contacto Alternativo"
                        value={candidate.personAlternateContact}
                        onChange={(e) =>
                          handleChange("personAlternateContact", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Fax/Telefax"
                        value={candidate.fax}
                        onChange={(e) => handleChange("fax", e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="E-mail Principal"
                        value={candidate.primaryEmail}
                        onChange={(e) =>
                          handleChange("primaryEmail", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                        required
                        error={errors.primaryEmail}
                      />
                      {errors.primaryEmail && (
                        <FormHelperText error>Campo obrigatório</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Email Alternativo"
                        value={candidate.alternateEmail}
                        onChange={(e) =>
                          handleChange("alternateEmail", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Website da Empresa do Candidato"
                        value={candidate.companyWebsite}
                        onChange={(e) =>
                          handleChange("companyWebsite", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="province-label">Província</InputLabel>
                        <Select
                          labelId="province-label"
                          value={candidate.province}
                          label="Província"
                          onChange={handleSelectChange("province")}
                          required
                          error={errors.province}
                        >
                          {dropdown_province.map((province) => (
                            <MenuItem key={province.text} value={province.text}>
                              {province.text}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.province && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Distrito"
                        value={candidate.district}
                        onChange={(e) =>
                          handleChange("district", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    label="Endereço Físico"
                    value={candidate.physicalAddress}
                    onChange={(e) =>
                      handleChange("physicalAddress", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Box>
              )}
              {activeStep === 3 && (
                <Box p={3}>
                  <Typography variant="h6">Perfil da Empresa</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="activitySector-label">
                          Sector de Actividade
                        </InputLabel>
                        <Select
                          labelId="activitySector-label"
                          value={candidate.activitySector}
                          label="Sector de Actividade"
                          onChange={handleSelectChange("activitySector")}
                        >
                          {dropdown_activitySector.map((activitySector) => (
                            <MenuItem
                              key={activitySector.text}
                              value={activitySector.text}
                            >
                              {activitySector.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Subsector de Atividade"
                        value={candidate.subActivitySector}
                        onChange={(e) =>
                          handleChange("subActivitySector", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Número de Funcionários"
                        type="number"
                        value={candidate.numberOfEmployees}
                        onChange={(e) =>
                          handleChange("numberOfEmployees", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="size-label">
                          Dimensão do Candidato
                        </InputLabel>
                        <Select
                          labelId="size-label"
                          value={candidate.size}
                          label="Dimensão do Candidato"
                          onChange={handleSelectChange("size")}
                        >
                          {dropdown_size.map((size) => (
                            <MenuItem key={size.text} value={size.text}>
                              {size.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Breve descrição das actividades da empresa do Candidato"
                        value={candidate.activitiesDescription}
                        onChange={(e) =>
                          handleChange("activitiesDescription", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="marketExperienceYears-label">
                          Antiguidade no Mercado
                        </InputLabel>
                        <Select
                          labelId="marketExperienceYears-label"
                          value={candidate.marketExperienceYears}
                          label="Antiguidade no Mercado"
                          onChange={handleSelectChange("marketExperienceYears")}
                        >
                          {dropdown_marketExperienceYears.map(
                            (marketExperienceYears) => (
                              <MenuItem
                                key={marketExperienceYears.text}
                                value={marketExperienceYears.text}
                              >
                                {marketExperienceYears.text}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="companyType-label">
                          Tipo de Sociedade
                        </InputLabel>
                        <Select
                          labelId="companyType-label"
                          value={candidate.companyType}
                          label="Tipo de Sociedade"
                          onChange={handleSelectChange("companyType")}
                        >
                          {dropdown_companyType.map((companyType) => (
                            <MenuItem
                              key={companyType.text}
                              value={companyType.text}
                            >
                              {companyType.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label>
                        A Empresa do Candidato possui Accionistas? Se sim, por
                        favor indentifique-os, indique a sua quota de
                        participação e nacionalidade
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      Por favor, forneça o detalhe abaixo, sobre os membros
                      Equipa Executiva da Empresa do Candidato*
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {" "}
                      {candidate.shareholders.map((shareholder, index) => (
                        <Box key={index} mb={2}>
                          <TextField
                            label="Nome"
                            value={shareholder.name}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "name",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Número de Ações"
                            value={shareholder.participation}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "participation",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Nacionalidade"
                            value={shareholder.nationality}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "nationality",
                                e.target.value,
                                "shareholders"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />

                          <IconButton
                            onClick={() =>
                              handleRemoveFromArray(index, "shareholders")
                            }
                          >
                            <Remove />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => handleAddToArray("shareholders")}
                      >
                        Adicionar Acionista
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      {candidate.executiveTeam.map((executive, index) => (
                        <Box key={index} mb={2}>
                          <TextField
                            label="Nome"
                            value={executive.name}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "name",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Função na Empresa"
                            value={executive.position}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "position",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Anos de Experiência na Indústria"
                            value={executive.industryExperienceYears}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "industryExperienceYears",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            label="Descrição da Experiência na Indústria"
                            value={executive.industryExperienceDescription}
                            onChange={(e) =>
                              handleArrayChange(
                                index,
                                "industryExperienceDescription",
                                e.target.value,
                                "executiveTeam"
                              )
                            }
                            fullWidth
                            margin="normal"
                          />
                          <IconButton
                            onClick={() =>
                              handleRemoveFromArray(index, "executiveTeam")
                            }
                          >
                            <Remove />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => handleAddToArray("executiveTeam")}
                      >
                        Adicionar Membro da Equipe
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 4 && (
                <Box p={3}>
                  <Typography variant="h6">Informação Legal</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasCommercialCertificate-label">
                          Certidão Comercial
                        </InputLabel>
                        <Select
                          labelId="hasCommercialCertificate-label"
                          value={candidate.hasCommercialCertificate}
                          label="Certidão Comercial"
                          onChange={handleSelectChange(
                            "hasCommercialCertificate"
                          )}
                        >
                          {dropdown_y_or_n.map((hasCommercialCertificate) => (
                            <MenuItem
                              key={hasCommercialCertificate.text}
                              value={hasCommercialCertificate.text}
                            >
                              {hasCommercialCertificate.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasINSSCertificate-label">
                          Certidão de Quitação do INSS
                        </InputLabel>
                        <Select
                          labelId="hasINSSCertificate-label"
                          value={candidate.hasINSSCertificate}
                          label="Certidão de Quitação do INSS"
                          onChange={handleSelectChange("hasINSSCertificate")}
                        >
                          {dropdown_y_or_n.map((hasINSSCertificate) => (
                            <MenuItem
                              key={hasINSSCertificate.text}
                              value={hasINSSCertificate.text}
                            >
                              {hasINSSCertificate.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasTaxNumberCompany-label">
                          NUIT Empresarial
                        </InputLabel>
                        <Select
                          labelId="hasTaxNumberCompany-label"
                          value={candidate.hasTaxNumberCompany}
                          label="NUIT"
                          onChange={handleSelectChange("hasTaxNumberCompany")}
                          required
                          error={errorsCampany.hasTaxNumberCompany}
                        >
                          {dropdown_y_or_n.map((hasTaxNumberCompany) => (
                            <MenuItem
                              key={hasTaxNumberCompany.text}
                              value={hasTaxNumberCompany.text}
                            >
                              {hasTaxNumberCompany.text}
                            </MenuItem>
                          ))}
                        </Select>{" "}
                        {errorsCampany.hasTaxNumberCompany && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasCourtClearanceCertificate-label">
                          Certidão de Quitação do Tribunal
                        </InputLabel>
                        <Select
                          labelId="hasCourtClearanceCertificate-label"
                          value={candidate.hasCourtClearanceCertificate}
                          label="Certidão de Quitação do Tribunal"
                          onChange={handleSelectChange(
                            "hasCourtClearanceCertificate"
                          )}
                        >
                          {dropdown_y_or_n.map(
                            (hasCourtClearanceCertificate) => (
                              <MenuItem
                                key={hasCourtClearanceCertificate.text}
                                value={hasCourtClearanceCertificate.text}
                              >
                                {hasCourtClearanceCertificate.text}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="alvara-label">Alvara</InputLabel>
                        <Select
                          labelId="alvara-label"
                          value={candidate.alvara}
                          label="Alvara"
                          onChange={handleSelectChange("alvara")}
                        >
                          {dropdown_y_or_n.map((alvara) => (
                            <MenuItem key={alvara.text} value={alvara.text}>
                              {alvara.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasFinanceClearanceCertificate-label">
                          Certidão de Quitação das Finanças
                        </InputLabel>
                        <Select
                          labelId="hasFinanceClearanceCertificate-label"
                          value={candidate.hasFinanceClearanceCertificate}
                          label="Certidão de Quitação das Finanças"
                          onChange={handleSelectChange(
                            "hasFinanceClearanceCertificate"
                          )}
                        >
                          {dropdown_y_or_n.map(
                            (hasFinanceClearanceCertificate) => (
                              <MenuItem
                                key={hasFinanceClearanceCertificate.text}
                                value={hasFinanceClearanceCertificate.text}
                              >
                                {hasFinanceClearanceCertificate.text}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasID-label">BI</InputLabel>
                        <Select
                          labelId="hasID-label"
                          value={candidate.hasID}
                          label="BI"
                          onChange={handleSelectChange("hasID")}
                          required
                          error={errorsCampany.hasID}
                        >
                          {dropdown_y_or_n.map((hasID) => (
                            <MenuItem key={hasID.text} value={hasID.text}>
                              {hasID.text}
                            </MenuItem>
                          ))}
                        </Select>{" "}
                        {errorsCampany.hasID && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasResidenceProof-label">
                          Comprovativo de Residência
                        </InputLabel>
                        <Select
                          labelId="hasResidenceProof-label"
                          value={candidate.hasResidenceProof}
                          label="Comprovativo de Residência"
                          onChange={handleSelectChange("hasResidenceProof")}
                        >
                          {dropdown_y_or_n.map((hasResidenceProof) => (
                            <MenuItem
                              key={hasResidenceProof.text}
                              value={hasResidenceProof.text}
                            >
                              {hasResidenceProof.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="hasTaxNumber-label">
                          NUIT Pessoal
                        </InputLabel>
                        <Select
                          labelId="hasTaxNumber-label"
                          value={candidate.hasTaxNumber}
                          label="NUIT"
                          onChange={handleSelectChange("hasTaxNumber")}
                          required
                          error={errorsCampany.hasTaxNumber}
                        >
                          {dropdown_y_or_n.map((hasTaxNumber) => (
                            <MenuItem
                              key={hasTaxNumber.text}
                              value={hasTaxNumber.text}
                            >
                              {hasTaxNumber.text}
                            </MenuItem>
                          ))}
                        </Select>{" "}
                        {errorsCampany.hasTaxNumber && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="incomeStatement-label">
                          Declaração de Rendimentos
                        </InputLabel>
                        <Select
                          labelId="incomeStatement-label"
                          value={candidate.incomeStatement}
                          label="Declaração de Rendimentos"
                          onChange={handleSelectChange("incomeStatement")}
                        >
                          {dropdown_y_or_n.map((incomeStatement) => (
                            <MenuItem
                              key={incomeStatement.text}
                              value={incomeStatement.text}
                            >
                              {incomeStatement.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="size-label">
                          Registo Criminal
                        </InputLabel>
                        <Select
                          labelId="size-label"
                          value={candidate.hasCriminalRecord}
                          label="Registo Criminal"
                          onChange={handleSelectChange("hasCriminalRecord")}
                        >
                          {dropdown_y_or_n.map((hasCriminalRecord) => (
                            <MenuItem
                              key={hasCriminalRecord.text}
                              value={hasCriminalRecord.text}
                            >
                              {hasCriminalRecord.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <h3>Imagem do BI</h3>
                      <FormControl fullWidth>
                        <Input type="file" onChange={handleImageBIUpload} />
                      </FormControl>
                      {(selectedBIImage || candidate.biPhoto) && (
                        <div>
                          <img
                            src={
                              selectedBIImage
                                ? (selectedBIImage as string)
                                : candidate.biPhoto
                            }
                            width="15%"
                            height="auto"
                          />
                          {candidate.id > 0 && candidate.biPhoto && (
                            <div>
                              <a href={candidate.biPhoto} download>
                                <IconButton color="primary">
                                  <DownloadIcon />
                                </IconButton>
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                      {candidate.hasID === "Sim" && (
                        <FormHelperText error>Campo obrigatório</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <h3>Imagem do NUIT</h3>
                      <FormControl fullWidth>
                        <Input type="file" onChange={handleImageNUITUpload} />
                      </FormControl>
                      {(selectedNUITImage || candidate.nuitPhoto) && (
                        <div>
                          <img
                            src={
                              selectedNUITImage
                                ? (selectedNUITImage as string)
                                : candidate.nuitPhoto
                            }
                            width="15%"
                            height="auto"
                          />
                          {candidate.id > 0 && candidate.nuitPhoto && (
                            <div>
                              <a href={candidate.nuitPhoto} download>
                                <IconButton color="primary">
                                  <DownloadIcon />
                                </IconButton>
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                      {(candidate.hasTaxNumber === "Sim" ||
                        candidate.hasTaxNumberCompany === "Sim") &&
                        mediaBiPhotoErrors.biPhoto && (
                          <FormHelperText error>
                            Campo obrigatório
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Caso tenha seleccionado a opção 'Não' para os campos descritos acima, por favor justifique."
                        value={candidate.noDocumentJustification}
                        onChange={(e) =>
                          handleChange(
                            "noDocumentJustification",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 5 && (
                <Box p={3}>
                  <Typography variant="h6">
                    Informação sobre a gestão da empresa do candidato
                  </Typography>

                  <Grid container spacing={2}>
                    {/* 5.1 Footprint e Capacidade Técnica */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Footprint e Capacidade Técnica
                      </Typography>
                    </Grid>

                    {/* Representação Regional */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasRegionalRepresentation-label">
                            A Empresa do Candidato possui representação na
                            região onde pretende prestar os serviços?*
                          </InputLabel>
                          <Select
                            labelId="hasRegionalRepresentation-label"
                            value={
                              candidate.candidateManagement
                                .hasRegionalRepresentation
                            }
                            label="A Empresa do Candidato possui representação na região onde pretende prestar os serviços?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasRegionalRepresentation: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique."
                          value={
                            candidate.candidateManagement
                              .regionalRepresentationDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                regionalRepresentationDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Expansão Geográfica */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="plansToExpandGeographies-label">
                            A Empresa do Candidato pretende expandir as suas
                            actividades nos próximos anos para outras
                            geografias?*
                          </InputLabel>
                          <Select
                            labelId="plansToExpandGeographies-label"
                            value={
                              candidate.candidateManagement
                                .plansToExpandGeographies
                            }
                            label="A Empresa do Candidato pretende expandir as suas actividades nos próximos anos para outras geografias?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  plansToExpandGeographies: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique e justifique-se."
                          value={candidate.candidateManagement.expansionDetails}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                expansionDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Fornecimento de Serviços fora das Regiões */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="providesServicesOutsideRegions-label">
                            A Empresa do Candidato fornece os seus Bens e
                            Serviços para outras regiões em que não tenha
                            sucursais (se aplicável)?*
                          </InputLabel>
                          <Select
                            labelId="providesServicesOutsideRegions-label"
                            value={
                              candidate.candidateManagement
                                .providesServicesOutsideRegions
                            }
                            label="A Empresa do Candidato fornece os seus Bens e Serviços para outras regiões em que não tenha sucursais (se aplicável)?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  providesServicesOutsideRegions:
                                    e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor indique."
                          value={
                            candidate.candidateManagement
                              .serviceOutsideRegionsDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                serviceOutsideRegionsDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Processos de HSE */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasHSEProcesses-label">
                            A Empresa do Candidado possui processos de HSE
                            (Saúde e Segurança no Trabalho) instituído na sua
                            empresa?*
                          </InputLabel>
                          <Select
                            labelId="hasHSEProcesses-label"
                            value={
                              candidate.candidateManagement.hasHSEProcesses
                            }
                            label="A Empresa do Candidado possui processos de HSE (Saúde e Segurança no Trabalho) instituído na sua empresa?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasHSEProcesses: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement.hseProcessesDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                hseProcessesDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Manutenção */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="performsMaintenance-label">
                            Existe algum tipo de manutenção (programada ou
                            corretiva) executada pela Empresa do Candidato? (Ex:
                            Equipamentos)*
                          </InputLabel>
                          <Select
                            labelId="performsMaintenance-label"
                            value={
                              candidate.candidateManagement.performsMaintenance
                            }
                            label="Existe algum tipo de manutenção (programada ou corretiva) executada pela Empresa do Candidato? (Ex: Equipamentos)*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  performsMaintenance: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement.maintenanceDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                maintenanceDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Unidade de Controle de Qualidade */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasQualityControlUnit-label">
                            A Empresa do Candidato possui alguma Unidade de
                            Controlo de Qualidade na sua estrutura
                            organizacional?*
                          </InputLabel>
                          <Select
                            labelId="hasQualityControlUnit-label"
                            value={
                              candidate.candidateManagement
                                .hasQualityControlUnit
                            }
                            label="A Empresa do Candidato possui alguma Unidade de Controlo de Qualidade na sua estrutura organizacional?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasQualityControlUnit: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement
                              .qualityControlUnitDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                qualityControlUnitDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Competências Técnicas */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasTechnicalCompetence-label">
                            A Empresa do Candidato possui Competências Técnicas
                            para responder a demanda do seu sector?*
                          </InputLabel>
                          <Select
                            labelId="hasTechnicalCompetence-label"
                            value={
                              candidate.candidateManagement
                                .hasTechnicalCompetence
                            }
                            label="A Empresa do Candidato possui Competências Técnicas para responder a demanda do seu sector?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasTechnicalCompetence: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor descreva."
                          value={
                            candidate.candidateManagement
                              .technicalCompetenceDetails
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                technicalCompetenceDetails: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.2 Capacidade de Gestão do Negócio */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Capacidade de Gestão do Negócio
                      </Typography>
                    </Grid>

                    {/* Código de Ética */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasEthicsCode-label">
                            A Empresa do Candidato possui um Código de Ética em
                            que actua em conformidade? Se sim, anexe o
                            comprovativo.
                          </InputLabel>
                          <Select
                            labelId="hasEthicsCode-label"
                            value={candidate.candidateManagement.hasEthicsCode}
                            label="A Empresa do Candidato possui um Código de Ética em que actua em conformidade? Se sim, anexe o comprovativo."
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasEthicsCode: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .ethicsCodeJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                ethicsCodeJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Estrutura Orgânica */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasOrganizationalStructure-label">
                            A Empresa do Candidato possui uma Estrutura Orgânica
                            que evidencie segregação de funções? Se sim, anexe o
                            organograma.
                          </InputLabel>
                          <Select
                            labelId="hasOrganizationalStructure-label"
                            value={
                              candidate.candidateManagement
                                .hasOrganizationalStructure
                            }
                            label="A Empresa do Candidato possui uma Estrutura Orgânica que evidencie segregação de funções? Se sim, anexe o organograma."
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasOrganizationalStructure: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .organizationalStructureJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                organizationalStructureJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Processos e Procedimentos */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasDefinedProcesses-label">
                            A Empresa do Candidato possui processos e
                            procedimentos devidamente definidos de acordo com as
                            suas actividades?
                          </InputLabel>
                          <Select
                            labelId="hasDefinedProcesses-label"
                            value={
                              candidate.candidateManagement.hasDefinedProcesses
                            }
                            label="A Empresa do Candidato possui processos e procedimentos devidamente definidos de acordo com as suas actividades?"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasDefinedProcesses: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .definedProcessesJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                definedProcessesJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Planeamento de Compras */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasPurchasePlanning-label">
                            A Empresa do Candidato possui algum planeamento para
                            realizar as suas compras?
                          </InputLabel>
                          <Select
                            labelId="hasPurchasePlanning-label"
                            value={
                              candidate.candidateManagement.hasPurchasePlanning
                            }
                            label="A Empresa do Candidato possui algum planeamento para realizar as suas compras?"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasPurchasePlanning: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .purchasePlanningJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                purchasePlanningJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.3 Recursos Humanos */}
                    <Grid item xs={12}>
                      <Typography variant="h6"> Recursos Humanos</Typography>
                    </Grid>

                    {/* Funções/Departamentos Estruturados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasStructuredFunctions-label">
                            A Empresa do Candidato possui as suas
                            funções/departamentos estruturados de acordo com o
                            seu tipo de negócio?*
                          </InputLabel>
                          <Select
                            labelId="hasStructuredFunctions-label"
                            value={
                              candidate.candidateManagement
                                .hasStructuredFunctions
                            }
                            label="A Empresa do Candidato possui as suas funções/departamentos estruturados de acordo com o seu tipo de negócio?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasStructuredFunctions: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .structuredFunctionsJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                structuredFunctionsJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Recursos Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateResources-label">
                            A Empresa do Candidato possui os recursos adequados
                            em número e qualidade para desempenhar as suas
                            actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateResources-label"
                            value={
                              candidate.candidateManagement.hasAdequateResources
                            }
                            label="A Empresa do Candidato possui os recursos adequados em número e qualidade para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateResources: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateResourcesJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateResourcesJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Igualdade de Género */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasGenderEquality-label">
                            Existe uma distribuição equitiva na igualdade de
                            género, na Empresa do Beneficiário?*
                          </InputLabel>
                          <Select
                            labelId="hasGenderEquality-label"
                            value={
                              candidate.candidateManagement.hasGenderEquality
                            }
                            label="Existe uma distribuição equitiva na igualdade de género, na Empresa do Beneficiário?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasGenderEquality: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .genderEqualityJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                genderEqualityJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Rotatividade */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasHighTurnover-label">
                            A Empresa do Candidato possui um elevado nível de
                            rotatividade?*
                          </InputLabel>
                          <Select
                            labelId="hasHighTurnover-label"
                            value={
                              candidate.candidateManagement.hasHighTurnover
                            }
                            label="A Empresa do Candidato possui um elevado nível de rotatividade?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasHighTurnover: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se sim, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .highTurnoverJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                highTurnoverJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.4 Informação Financeira */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Informação Financeira
                      </Typography>
                    </Grid>

                    {/* Regime de Tributação */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Por favor, indique o Regime de Tributação da Empresa do Candidato?"
                          value={candidate.candidateManagement.taxRegime}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                taxRegime: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Qual é a data de reporte mais recente das Demonstrações Financeiras da Empresa do Candidato?*"
                          value={
                            candidate.candidateManagement
                              .latestFinancialReportDate
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                latestFinancialReportDate: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Volume de Negócios e Auditoria */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Volume de Negócios Anual da Empresa do Candidato"
                          value={candidate.candidateManagement.annualTurnover}
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                annualTurnover: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="As Demonstrações da Empresa são Auditadas por um
                              auditor Independente? Se sim, por favor indique a
                              frequência."
                          value={
                            candidate.candidateManagement
                              .financialStatementsAudited
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                financialStatementsAudited: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Frequência de Produção das Demonstrações Financeiras */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="financialStatementsFrequency-label">
                            Frequência de produção das Demonstrações Financeiras
                            da Empresa do Candidato
                          </InputLabel>
                          <Select
                            labelId="financialStatementsFrequency-label"
                            value={
                              candidate.candidateManagement
                                .financialStatementsFrequency
                            }
                            label="Frequência de produção das Demonstrações Financeiras da Empresa do Candidato"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  financialStatementsFrequency: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_quarter_year.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Por favor, forneça as Demonstrações Financeiras dos últimos 3 anos Financeiros (se aplicável)"
                          value={
                            candidate.candidateManagement
                              .financialStatementsLast3Years
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                financialStatementsLast3Years: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* 5.5 Sistemas e Equipamentos */}
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Sistemas e Equipamentos
                      </Typography>
                    </Grid>

                    {/* Equipamentos Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateEquipment-label">
                            A Empresa do Candidato possui equipamentos adequados
                            para desempenhar as suas actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateEquipment-label"
                            value={
                              candidate.candidateManagement.hasAdequateEquipment
                            }
                            label="A Empresa do Candidato possui equipamentos adequados para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateEquipment: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateEquipmentJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateEquipmentJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Sistemas Adequados */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateSystems-label">
                            A Empresa do Candidato possui sistemas adequados
                            para desempenhar as suas actividades?*
                          </InputLabel>
                          <Select
                            labelId="hasAdequateSystems-label"
                            value={
                              candidate.candidateManagement.hasAdequateSystems
                            }
                            label="A Empresa do Candidato possui sistemas adequados para desempenhar as suas actividades?*"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateSystems: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateInfrastructureJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateInfrastructureJustification:
                                  e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    {/* Infraestruturas Adequadas */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="hasAdequateInfrastructure-label">
                            A Empresa do Candidato possui infraestruturas
                            adequadas às suas actividades?* Se sim, por favor
                            forneça Imagens das suas instalações
                          </InputLabel>
                          <Select
                            labelId="hasAdequateInfrastructure-label"
                            value={
                              candidate.candidateManagement
                                .hasAdequateInfrastructure
                            }
                            label="A Empresa do Candidato possui infraestruturas adequadas às suas actividades?* Se sim, por favor forneça Imagens das suas instalações"
                            onChange={(e) =>
                              setCandidate((prevCandidate) => ({
                                ...prevCandidate,
                                candidateManagement: {
                                  ...prevCandidate.candidateManagement,
                                  hasAdequateInfrastructure: e.target.value,
                                },
                              }))
                            }
                          >
                            {dropdown_yes_or_no.map((item) => (
                              <MenuItem key={item.text} value={item.text}>
                                {item.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Se não, por favor justifique."
                          value={
                            candidate.candidateManagement
                              .adequateSystemsJustification
                          }
                          onChange={(e) =>
                            setCandidate((prevCandidate) => ({
                              ...prevCandidate,
                              candidateManagement: {
                                ...prevCandidate.candidateManagement,
                                adequateSystemsJustification: e.target.value,
                              },
                            }))
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box mt={2} mb={2} display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={() => handleStepChange(activeStep - 1, "back")}
                  sx={{ mr: 2 }}
                >
                  Anterior
                </Button>

                {activeStep !== formtype && (
                  <Button
                    variant="outlined"
                    onClick={() => handleStepChange(activeStep + 1, "next")}
                    sx={{ mx: 2 }}
                  >
                    Próximo
                  </Button>
                )}

                {activeStep === formtype &&
                  (candidatureStatus === "Não publicada" ||
                  candidatureStatus === null ||
                  finalBtn === true ||
                  candidatureStatus === "" ? (
                    <>
                      <Box
                        mt={2}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {loader ? (
                          ""
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (id > 0 && userType != "ROLE_CANDIDATE") {
                                toast.warning(
                                  `Somente o Candidato ${candidate.candidateName} é que deve atualizar os dados`
                                );
                              } else {
                                // handleOpenLoader();
                                handleOpenDialog();
                              }
                            }}
                          >
                            Concluir
                          </Button>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button variant="contained" color="primary" disabled>
                        Estado: {candidatureStatus}
                      </Button>
                    </>
                  ))}
              </Box>
            </div>
          </Box>
        </CardContent>
      </Card>
      {loader ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        ""
      )}
      <BackButton />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Resumo dos Dados</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeStepTab}
                onChange={handleChangetab}
                aria-label="basic tabs example"
              >
                {activeStep >= 1 && (
                  <Tab label="Assistência Requerida" {...a11yProps(0)} />
                )}
                {activeStep >= 2 && (
                  <Tab
                    label="Informações Gerais do Candidato"
                    {...a11yProps(1)}
                  />
                )}
                {activeStep >= 3 && (
                  <Tab label="Perfil da Empresa" {...a11yProps(2)} />
                )}
                {activeStep >= 4 && (
                  <Tab label="Informação Legal" {...a11yProps(3)} />
                )}
                {activeStep >= 5 && (
                  <Tab
                    label="Informação sobre a gestão da empresa do candidato"
                    {...a11yProps(4)}
                  />
                )}
              </Tabs>
            </Box>
            <CustomTabPanel value={activeStepTab} index={0}>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStepChange(1, "next");
                    handleCloseDialog();
                  }}
                  sx={{ mb: 2 }}
                >
                  Alterar Dados da Seção : Assistência Requerida
                </Button>
              </Box>
              <TabPanel value={activeStepTab} index={0}>
                <Box mt={2}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "1rem", textAlign: "center" }}
                    >
                      Assistência Requerida
                    </Typography>
                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        "& th, & td": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Assistência Desejada:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.desiredAssistance || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Assistência Adicional:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.additionalAssistance || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Detalhes da Assistência:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.assistanceDetails || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Finalidade da Assistência:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.assistancePurpose || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Montante Requerido (se aplicável):</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.requestedInvestmentAmount || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>
                              Criação de Novos Postos de Trabalho:
                            </strong>
                          </TableCell>
                          <TableCell>
                            {candidate.createsNewJobs ? "Sim" : "Não"}
                          </TableCell>
                        </TableRow>
                        {candidate.createsNewJobs && (
                          <TableRow>
                            <TableCell>
                              <strong>
                                Estimativa Anual de Criação de Postos:
                              </strong>
                            </TableCell>
                            <TableCell>
                              {candidate.estimatedAnnualJobCreation || ""}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>
                            <strong>Benefício Anterior:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.previouslyBenefited ? "Sim" : "Não"}
                          </TableCell>
                        </TableRow>
                        {candidate.previouslyBenefited && (
                          <TableRow>
                            <TableCell>
                              <strong>Detalhes da Assistência Anterior:</strong>
                            </TableCell>
                            <TableCell>
                              {candidate.previousAssistanceDetails || ""}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>
                            <strong>Aspectos Adicionais:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.additionalAspects || ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </CustomTabPanel>
            <CustomTabPanel value={activeStepTab} index={1}>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStepChange(2, "next");
                    handleCloseDialog();
                  }}
                  sx={{ mb: 2 }}
                >
                  Alterar Dados da Seção : Informações Gerais do Candidato
                </Button>
              </Box>
              <TabPanel value={activeStepTab} index={1}>
                <Box mt={2}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "1rem", textAlign: "center" }}
                    >
                      Informações Gerais do Candidato
                    </Typography>

                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        "& th, & td": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Categoria do Candidato:</strong>
                          </TableCell>
                          <TableCell>{candidate.category || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Nome:</strong>
                          </TableCell>
                          <TableCell>{candidate.firstName || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Sobrenome:</strong>
                          </TableCell>
                          <TableCell>{candidate.surname || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Ano de Fundação (se aplicável):</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.companyFoundationYear || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Pessoa de Contato:</strong>
                          </TableCell>
                          <TableCell>{candidate.personName || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Contato Principal:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.personPrimaryContact || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Pessoa de Contato Alternativo:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.personAlternateContact || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Fax/Telefax:</strong>
                          </TableCell>
                          <TableCell>{candidate.fax || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>E-mail Principal:</strong>
                          </TableCell>
                          <TableCell>{candidate.primaryEmail || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Email Alternativo:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.alternateEmail || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Website da Empresa:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.companyWebsite || ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Província:</strong>
                          </TableCell>
                          <TableCell>{candidate.province || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Distrito:</strong>
                          </TableCell>
                          <TableCell>{candidate.district || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Endereço Físico:</strong>
                          </TableCell>
                          <TableCell>
                            {candidate.physicalAddress || ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </CustomTabPanel>
            <CustomTabPanel value={activeStepTab} index={2}>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStepChange(3, "next");
                    handleCloseDialog();
                  }}
                  sx={{ mb: 2 }}
                >
                  Alterar Dados da Seção :Perfil da Empresa
                </Button>
              </Box>
              <TabPanel value={activeStepTab} index={2}>
                <Box p={3}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "1rem", textAlign: "center" }}
                    >
                      Perfil da Empresa
                    </Typography>

                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        "& th, & td": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">
                            Sector de Actividade
                          </TableCell>
                          <TableCell align="right">
                            {candidate.activitySector}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            Subsector de Atividade
                          </TableCell>
                          <TableCell align="right">
                            {candidate.subActivitySector}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            Número de Funcionários
                          </TableCell>
                          <TableCell align="right">
                            {candidate.numberOfEmployees}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            Dimensão do Candidato
                          </TableCell>
                          <TableCell align="right">{candidate.size}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            Descrição das Atividades
                          </TableCell>
                          <TableCell align="right">
                            {candidate.activitiesDescription}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            Antiguidade no Mercado
                          </TableCell>
                          <TableCell align="right">
                            {candidate.marketExperienceYears}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Tipo de Sociedade</TableCell>
                          <TableCell align="right">
                            {candidate.companyType}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Accionistas</TableCell>
                          <TableCell align="right">
                            {candidate.shareholders.map(
                              (shareholder, index) => (
                                <Box key={index} mb={2}>
                                  <Typography variant="body2">
                                    Nome: {shareholder.name}
                                  </Typography>
                                  <Typography variant="body2">
                                    Participação: {shareholder.participation}
                                  </Typography>
                                  <Typography variant="body2">
                                    Nacionalidade: {shareholder.nationality}
                                  </Typography>
                                </Box>
                              )
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Equipe Executiva</TableCell>
                          <TableCell align="right">
                            {candidate.executiveTeam.map((executive, index) => (
                              <Box key={index} mb={2}>
                                <Typography variant="body2">
                                  Nome: {executive.name}
                                </Typography>
                                <Typography variant="body2">
                                  Função: {executive.position}
                                </Typography>
                                <Typography variant="body2">
                                  Anos de Experiência:{" "}
                                  {executive.industryExperienceYears}
                                </Typography>
                                <Typography variant="body2">
                                  Descrição da Experiência:{" "}
                                  {executive.industryExperienceDescription}
                                </Typography>
                              </Box>
                            ))}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </CustomTabPanel>
            <CustomTabPanel value={activeStepTab} index={3}>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStepChange(4, "next");
                    handleCloseDialog();
                  }}
                  sx={{ mb: 2 }}
                >
                  Alterar Dados da Seção: Informação Legal
                </Button>
              </Box>
              <TabPanel value={activeStepTab} index={3}>
                <Box p={3}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "1rem", textAlign: "center" }}
                    >
                      Informação Legal
                    </Typography>

                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        "& th, & td": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell>Certidão Comercial</TableCell>
                          <TableCell>
                            {candidate.hasCommercialCertificate}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Certidão de Quitação do INSS</TableCell>
                          <TableCell>{candidate.hasINSSCertificate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>NUIT Empresarial</TableCell>
                          <TableCell>{candidate.hasTaxNumberCompany}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Certidão de Quitação do Tribunal
                          </TableCell>
                          <TableCell>
                            {candidate.hasCourtClearanceCertificate}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Alvara</TableCell>
                          <TableCell>{candidate.alvara}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Certidão de Quitação das Finanças
                          </TableCell>
                          <TableCell>
                            {candidate.hasFinanceClearanceCertificate}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>BI</TableCell>
                          <TableCell>{candidate.hasID}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Comprovativo de Residência</TableCell>
                          <TableCell>{candidate.hasResidenceProof}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>NUIT Pessoal</TableCell>
                          <TableCell>{candidate.hasTaxNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Declaração de Rendimentos</TableCell>
                          <TableCell>{candidate.incomeStatement}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Registo Criminal</TableCell>
                          <TableCell>{candidate.hasCriminalRecord}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </CustomTabPanel>
            <CustomTabPanel value={activeStepTab} index={4}>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleStepChange(5, "next");
                    handleCloseDialog();
                  }}
                  sx={{ mb: 2 }}
                >
                  Alterar Dados da Seção: Informação sobre a gestão da empresa
                  do candidato
                </Button>
              </Box>
              <TabPanel value={activeStepTab} index={4}>
                <Box p={3}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "1rem", textAlign: "center" }}
                    >
                      Informação sobre a gestão da empresa do candidato
                    </Typography>

                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        "& th, & td": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Categoria</TableCell>
                          <TableCell>Informação</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Footprint e Capacidade Técnica
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui representação na
                            região onde pretende prestar os serviços?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasRegionalRepresentation
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor indique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .regionalRepresentationDetails
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato pretende expandir as suas
                            atividades nos próximos anos para outras geografias?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .plansToExpandGeographies
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Se sim, por favor indique e justifique-se.
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.expansionDetails}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato fornece os seus Bens e
                            Serviços para outras regiões em que não tenha
                            sucursais (se aplicável)?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .providesServicesOutsideRegions
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor indique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .serviceOutsideRegionsDetails
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidado possui processos de HSE
                            (Saúde e Segurança no Trabalho) instituído na sua
                            empresa?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasHSEProcesses}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor descreva.</TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hseProcessesDetails}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Existe algum tipo de manutenção (programada ou
                            corretiva) executada pela Empresa do Candidato? (Ex:
                            Equipamentos)
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.performsMaintenance}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor descreva.</TableCell>
                          <TableCell>
                            {candidate.candidateManagement.maintenanceDetails}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui alguma Unidade de
                            Controlo de Qualidade na sua estrutura
                            organizacional?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasQualityControlUnit
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor descreva.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .qualityControlUnitDetails
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui Competências Técnicas
                            para responder a demanda do seu sector?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasTechnicalCompetence
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor descreva.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .technicalCompetenceDetails
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Capacidade de Gestão do Negócio
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui um Código de Ética em
                            que actua em conformidade? Se sim, anexe o
                            comprovativo.
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasEthicsCode}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .ethicsCodeJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Estrutura Orgânica
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui uma Estrutura Orgânica
                            que evidencie segregação de funções?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasOrganizationalStructure
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .organizationalStructureJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Processos e Procedimentos
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui processos e
                            procedimentos devidamente definidos de acordo com as
                            suas actividades?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasDefinedProcesses}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .definedProcessesJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Planeamento de Compras
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui algum planeamento para
                            realizar as suas compras?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasPurchasePlanning}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .purchasePlanningJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Recursos Humanos
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui as suas
                            funções/departamentos estruturados de acordo com o
                            seu tipo de negócio?
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasStructuredFunctions
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .structuredFunctionsJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui os recursos adequados
                            em número e qualidade para desempenhar as suas
                            actividades?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasAdequateResources}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .adequateResourcesJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Existe uma distribuição equitiva na igualdade de
                            género, na Empresa do Beneficiário?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasGenderEquality}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .genderEqualityJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui um elevado nível de
                            rotatividade?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasHighTurnover}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se sim, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .highTurnoverJustification
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Informação Financeira
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Por favor, indique o Regime de Tributação da Empresa
                            do Candidato?
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.taxRegime}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Qual é a data de reporte mais recente das
                            Demonstrações Financeiras da Empresa do Candidato?*
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .latestFinancialReportDate
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Volume de Negócios Anual da Empresa do Candidato
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.annualTurnover}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            As Demonstrações da Empresa são Auditadas por um
                            auditor Independente? Se sim, por favor indique a
                            frequência.
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .financialStatementsAudited
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Frequência de produção das Demonstrações Financeiras
                            da Empresa do Candidato
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .financialStatementsFrequency
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Por favor, forneça as Demonstrações Financeiras dos
                            últimos 3 anos Financeiros (se aplicável)
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .financialStatementsLast3Years
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">
                              Sistemas e Equipamentos
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui equipamentos adequados
                            para desempenhar as suas actividades?*
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasAdequateEquipment}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .adequateEquipmentJustification
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui sistemas adequados
                            para desempenhar as suas actividades?*
                          </TableCell>
                          <TableCell>
                            {candidate.candidateManagement.hasAdequateSystems}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .adequateInfrastructureJustification
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            A Empresa do Candidato possui infraestruturas
                            adequadas às suas actividades?* Se sim, por favor
                            forneça Imagens das suas instalações
                          </TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .hasAdequateInfrastructure
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Se não, por favor justifique.</TableCell>
                          <TableCell>
                            {
                              candidate.candidateManagement
                                .adequateSystemsJustification
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </CustomTabPanel>
          </Box>
        </DialogContent>
        {loader ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          ""
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Fechar
          </Button>
          {loader ? (
            ""
          ) : (
            <Button onClick={() => handleSubmit()} color="primary">
              Confirmar e Submeter
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CandidateForm;
