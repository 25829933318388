import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Grid,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

import { ReactComponent as Logo } from "../../vendor/logo-hr-blue-tr.svg";
import { ReactComponent as LeftImage } from "../../vendor/leftimage.svg";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`;

const LeftImageVar = styled(LeftImage)`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`;

const Container = styled(Box)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1200px;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

const LeftSection = styled(Box)`
  flex: 1;
  padding: 16px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-color: rgb(0, 118, 128);
`;

const RightSection = styled(Box)`
  flex: 1;
  padding: 32px;
`;

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Container>
      <ToastContainer position="top-right" />
      <FormPaper>
        {/* Left Section */}
        <LeftSection>
          <LeftImageVar />
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Através da nossa Ferramenta de Gestão, ajustamos o apoio às
            necessidades específicas do seu negócio, garantindo um processo
            eficiente e transparente. Junte-se a nós e impulsione o crescimento
            da sua empresa !
          </Typography>
        </LeftSection>

        {/* Right Section */}
        <RightSection>
          <Brand />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Bem-vindo!
          </Typography>
          <Formik
            initialValues={{
              username: "",
              password: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required("Username is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await signIn(values.username, values.password);
                navigate("/dashboard");
              } catch (error: any) {
                const message =
                  error.message || "Something went wrong! Contact site admin.";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={3} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="text"
                  name="username"
                  label="Username"
                  value={values.username}
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  style={{
                    marginTop: "16px",
                    marginBottom: "16px",
                    padding: "12px 0",
                  }}
                >
                  Entrar
                </Button>

                <Button
                  component={Link}
                  to="/auth/signup"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  style={{ marginBottom: "16px", padding: "12px 0" }}
                >
                  Inscreva-se
                </Button>

                <Button
                  component={Link}
                  to="/auth/reset-password"
                  fullWidth
                  variant="text"
                  color="primary"
                  style={{ padding: "12px 0" }}
                >
                  Recuperar Senha
                </Button>
              </form>
            )}
          </Formik>
        </RightSection>
      </FormPaper>
    </Container>
  );
}

export default SignIn;
