import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { use } from "i18next";
import { useEffect } from "react";
import { OlogaButton } from "../Buttons/OlogaButton";
import { Box } from "@mui/material";

interface OlogaConfirmDialogProps {
  title: string;
  description: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  value?: any;
  handleConfirm?: (value: any) => void;
}

function OlogaAlert({
  title,
  description,
  open,
  setOpen,
  value,
  handleConfirm,
}: OlogaConfirmDialogProps) {
  //   const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box mb={3} mr={2}>
            <OlogaButton label="OK" onClick={handleConfirm} />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OlogaAlert;
