import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box, CardContent, Card } from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";

interface ActionPlanFormValues {
  id: number;
  responsable: string;
  impact: string;
  startDate: string;
  endDate: string;
  status: number;
  actionPlanId: number;
  actionScoreItemId: number;
  assessmentScoreId: number;
  candidateId: number;
  fullName: string;
  fullNameGap: string;
  fullNameCandidate: string;
  analysCategory: string;
  specificAditionalNeeds: string;
  category: string;
  subActivitySector: string;
  companyType: string;
  gapAssessmentId: number;
}

const ActionPlanForm: React.FC<{
  assessmentScoreId: number;
  onClose: () => void;
  actionPlanDto: any;
}> = ({ assessmentScoreId, onClose, actionPlanDto }) => {
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState<ActionPlanFormValues>({
    id: state.actionPlanDto.id ?? 0,
    responsable: state.actionPlanDto.responsabe ?? "",
    impact: state.actionPlanDto.impact ?? "",
    startDate: state.actionPlanDto.startDate ?? "",
    endDate: state.actionPlanDto.endDate ?? "",
    status: state.actionPlanDto.status ?? 0,
    actionPlanId: state.actionPlanDto.actionPlanId ?? 0,
    actionScoreItemId: 0,
    assessmentScoreId: state.assessmentScoreId,
    candidateId: state.actionPlanDto.candidateId,
    fullName: state.actionPlanDto.fullName ?? "",
    fullNameGap: state.actionPlanDto.fullNameGap ?? "",
    fullNameCandidate: state.actionPlanDto.fullNameCandidate ?? "",
    analysCategory: state.actionPlanDto.analysCategory ?? "",
    specificAditionalNeeds: state.actionPlanDto.specificAditionalNeeds ?? "",
    category: state.actionPlanDto.category ?? "",
    subActivitySector: state.actionPlanDto.subActivitySector ?? "",
    companyType: state.actionPlanDto.companyType ?? "",
    gapAssessmentId: state.actionPlanDto.gapAssessmentId ?? 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    console.log(state.actionPlanDto);
    if (state.actionPlanDto.actionPlanId != null) {
      const fetchData = async () => {
        try {
          if (state.actionPlanDto?.status != "No Plano de Ação found") {
            const response = await http.get(
              `/actionPlan/${state.actionPlanDto.actionPlanId}`
            );
            if (response.data) {
              setInitialValues(response.data);
            }
          }
        } catch (error) {
          console.error("Error fetching Plano de Ação:", error);
        }
      };
      fetchData();
    }
  }, [state.assessmentScoreId, state.actionPlanDto]);

  const handleOpenForm = () => {
    navigate(`../issue-beneficary-form`, {
      state: {
        uri: `/project-log/candidate/${state.candidateId}/ISSUE_RISK_BENEFICIARY`,
        candidateId: state.candidateId,
      },
    });
  };

  const handleSubmit = async (
    values: ActionPlanFormValues,
    actions: FormikHelpers<ActionPlanFormValues>
  ) => {
    try {
      console.log(values);
      values["candidateId"] = state.actionPlanDto.candidateId;
      values["gapAssessmentId"] = state.actionPlanDto.gapAssessmentId;
      values["status"] = 0;

      if (state.actionPlanDto?.status != "No Action Plan found") {
        // if (values.id > 0) {
        values["id"] = state.actionPlanDto.actionPlanId;

        await http.post(`/actionPlan`, values);
        toast.success("Operação realizada com sucesso");
        navigate(-1);

        // }
      } else {
        const response = await http.post("/actionPlan", values);
        if (response.status === 200)
          toast.success("Operação realizada com sucesso");
        navigate(-1);
      }
      // onClose(); // Fechar o modal após salvar
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    responsable: Yup.string().required("Responsable is required"),
    impact: Yup.string().required("Impact is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Formulário do Plano de Acção"}
        buttonLabel="Adicionar"
        onClick={handleOpenForm}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="responsable"
                              label="Responsável"
                              fullWidth
                              value={values.responsable}
                              onChange={handleChange}
                              error={
                                touched.responsable &&
                                Boolean(errors.responsable)
                              }
                              helperText={<ErrorMessage name="responsable" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="impact"
                              label="Impacto"
                              fullWidth
                              value={values.impact}
                              onChange={handleChange}
                              error={touched.impact && Boolean(errors.impact)}
                              helperText={<ErrorMessage name="impact" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="startDate"
                              label="Data de Início"
                              type="date"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={values.startDate}
                              onChange={handleChange}
                              error={
                                touched.startDate && Boolean(errors.startDate)
                              }
                              helperText={<ErrorMessage name="startDate" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="endDate"
                              label="Data de Término"
                              type="date"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={values.endDate}
                              onChange={handleChange}
                              error={touched.endDate && Boolean(errors.endDate)}
                              helperText={<ErrorMessage name="endDate" />}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <OlogaButtonGroup />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
          <ToastContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ActionPlanForm;
