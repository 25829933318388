import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import OlogaSubmitButton from "../../components/Buttons/OlogaSubmitButton";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import OlogaSelect from "../../components/select/OlogaSelect";
import { format, parse } from "date-fns";

interface LogFormValues {
  id: number;
  candidateId: number;
  projectName: string;
  executionPhase: string;
  responsiblePerson: string;
  approvedInvestment: number;
  executedInvestment: number;
  comments: string;
  beneficiary: string;
  status: string;
  startDate: any;
  pubDate: any;
}
interface dropdownDTO {
  text: string;
  value: number;
}
const ProjectLogForm: React.FC<{
  onClose: () => void;
  dto: any;
  candidateId: any;
  handleOpenGapList: () => void;
}> = ({ onClose, dto, candidateId, handleOpenGapList }) => {
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState<LogFormValues>({
    id: state ? state.dto.id ?? "" : "",
    comments: state ? state.dto.comments ?? "" : "",
    candidateId: candidateId,
    beneficiary: state ? state.dto.beneficiary ?? "" : "",
    projectName: state ? state.dto.projectName ?? "" : "",
    executionPhase: state ? state.dto.executionPhase ?? "" : "",
    responsiblePerson: state ? state.dto.responsiblePerson ?? "" : "",
    approvedInvestment: state ? state.dto.approvedInvestment ?? 0 : 0,
    executedInvestment: state ? state.dto.executedInvestment ?? 0 : 0,
    startDate: state
      ? state
        ? parse(state.dto.startDate, "dd/MM/yyyy", new Date()) ?? ""
        : ""
      : "",
    pubDate: state
      ? state
        ? parse(state.dto.pubDate, "dd/MM/yyyy", new Date()) ?? ""
        : ""
      : "",
    status: state ? state.dto.status ?? "" : "",
  });
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);

  const status: Item[] = [
    { id: "OPEN", name: "Aberto" },
    { id: "PROGRESS", name: "Em Progresso" },
    { id: "RESOLVED", name: "Resolvido" },
    { id: "CLOSED", name: "Fechado" },
  ];

  const executionPhase: Item[] = [
    { id: "PLANNING", name: "Planificação" },
    { id: "PROGRESS", name: "Progresso" },
    { id: "INITIAL", name: "Inicial" },
    { id: "COMPLETED", name: "Completo" },
    { id: "MONITORING", name: "Monitorização" },
  ];

  interface Item {
    id: string;
    name: string;
  }

  useEffect(() => {}, []);

  const handleSubmit = async (
    values: LogFormValues,
    actions: FormikHelpers<LogFormValues>
  ) => {
    try {
      values["candidateId"] = candidateId;
      values["startDate"] = format(values.startDate, "dd/MM/yyyy");
      values["pubDate"] = format(values.pubDate, "dd/MM/yyyy");

      const data = { ...values };
      const response = await http.post("/project-log/projectlog", data);
      if (response.status === 200) {
        toast.success("Operação realizada com sucesso");
        navigate(`/assessment-admin/project-log/details/${candidateId}`);
      }
    } catch (error) {
      console.error("Error creating/updating :", error);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    projectName: Yup.string().required("Project Name is required"),
    approvedInvestment: Yup.string().required(
      "Approved Investment Category is required"
    ),
    // startDate: Yup.date().required("Start Date is required"),
    // endDate: Yup.date()
    //   .required("End Date is required")
    //   .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="projectName"
                          label="Nome do Projecto"
                          fullWidth
                          value={values.projectName}
                          onChange={handleChange}
                          error={
                            touched?.projectName && Boolean(errors.projectName)
                          }
                          helperText={<ErrorMessage name="analysCategory" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaSelect
                          name="executionPhase"
                          options={executionPhase as any}
                          label="Fase"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="responsiblePerson"
                          label="Responsável"
                          fullWidth
                          value={values.responsiblePerson}
                          onChange={handleChange}
                          error={
                            touched.responsiblePerson &&
                            Boolean(errors.responsiblePerson)
                          }
                          helperText={<ErrorMessage name="responsiblePerson" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaSelect
                          name="status"
                          options={status as any}
                          label="Estado"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="approvedInvestment"
                          label="Investimento Aprovado"
                          fullWidth
                          value={values.approvedInvestment}
                          onChange={handleChange}
                          error={
                            touched.approvedInvestment &&
                            Boolean(errors.approvedInvestment)
                          }
                          helperText={
                            <ErrorMessage name="approvedInvestment" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="executedInvestment"
                          label="Investimento Executado"
                          fullWidth
                          value={values.executedInvestment}
                          onChange={handleChange}
                          error={
                            touched.executedInvestment &&
                            Boolean(errors.executedInvestment)
                          }
                          helperText={
                            <ErrorMessage name="executedInvestment" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaDatePicker
                          name="startDate"
                          label="Data de Incício"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaDatePicker
                          name="pubDate"
                          label="Data de de Término"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="comments"
                          label="Comentários"
                          fullWidth
                          value={values.comments}
                          onChange={handleChange}
                          error={touched.comments && Boolean(errors.comments)}
                          helperText={<ErrorMessage name="comments" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaButtonGroup />
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default ProjectLogForm;
