import { OlogaButton } from "./OlogaButton";

interface OlogaSubmitButtonProps {
  label?: string;
}

function OlogaSubmitButton({ label = "Salvar" }: OlogaSubmitButtonProps) {
  return <OlogaButton type="submit" label={label} />;
}

export default OlogaSubmitButton;
