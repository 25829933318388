import React from "react";
import { useNavigate } from "react-router-dom";
import { OlogaButton } from "./OlogaButton";
import { Card as MuiCard, Grid, CardContent, Card } from "@mui/material";
import Box from "@mui/material/Box";
const BackButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card style={{ marginTop: "10px" }}>
      <CardContent>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <>
            <OlogaButton label={"Voltar"} onClick={() => navigate(-1)} />
          </>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BackButton;
