import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { toolSchema } from "../../utils/formValidation";
import ToolForm from "./ToolForm";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaCancelButton from "../../components/Buttons/OlogaCancelButton";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import axios from "axios";
import { Try } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";

const Card = styled(MuiCard)(spacing);
interface RowInterface {
  id: number;
  name: string;
  description: string;
  assessment_id: number;
}
interface Answer {
  id: number;
  name: string;
  description: string;
  Question: { id: number };
  questionId: number;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ToolUpdate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [categoryOpen, setcategoryOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const categoryHandleOpen = () => setcategoryOpen(true);
  const categoryHandleClose = () => setcategoryOpen(false);
  const [openAnwers, setOpenAnwers] = useState(false);
  const [newAnswers, setNewAnswers] = useState<string[]>([""]);
  const handleAnwersOpen = () => setOpenAnwers(true);
  const handleAnwersClose = () => setOpenAnwers(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [questiontype, setQuestiontype] = useState([]);
  const [selectedQuestiontype, setSelectedQuestiontype] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [questioncategory, setquestioncategory] = useState([]);
  const [selectedQuestioncategory, setSelectedQuestioncategory] = useState();
  const [selecttedValueString, SetselecttedValueString] = useState("");
  const [questionRow, setQuestionRow] = useState<RowInterface>();
  const [answers, setAnswers] = useState<Answer[]>();
  const [newCategory, setNewCategory] = useState("");
  const [options, setOptions] = useState([""]);

  const handleQuestioncategoryChange = (value: any) => {
    setSelectedQuestioncategory(value);
  };
  const handleQuestiontypeChange = (value: any) => {
    setSelectedQuestiontype(value);
  };

  const [question, setQuestion] = useState({
    id: 0,
    description: "",
    name: "",
    categoryId: "",
    typeId: "",
    toolId: state,
    answers: [],
  });

  const handleOpen = () => {
    const q = {
      id: 0,
      description: "",
      name: "",
      categoryId: "",
      typeId: "",
      toolId: state,
      answers: [],
    };
    setAnswers(q.answers);
    setQuestion(q);
    setOpen(true);
  };

  const handleOpenEditQuestion = (row: RowInterface) => {
    const q = {
      id: 0,
      description: "",
      name: "",
      categoryId: "",
      typeId: "",
      toolId: state,
      answers: [],
    };

    q.id = (row as any).id;
    q.description = (row as any).description;
    q.name = (row as any).name;
    q.categoryId = (row as any).category.id;
    q.typeId = (row as any).type.id;
    q.toolId = (row as any).tool.id;
    q.answers = (row as any).answers;
    setAnswers(q.answers);
    setQuestion(q);
    setOpen(true);
  };

  const handleOptionChange = (index: any, event: any) => {
    if (!answers) {
      console.error("Answers is undefined.");
      return; // Exit early if answers is undefined
    }

    const newAnswers = [...answers];

    if (newAnswers[index]) {
      newAnswers[index] = {
        ...newAnswers[index],
        description: event.target.value,
      };
    }
    setAnswers(newAnswers);
  };

  const handleNewCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewCategory(event.target.value);
  };

  const updateAnswer = async (answer: Answer) => {
    try {
      const response = await http.post(`/answers/update/`, answer);
      toast.success("Operação realizada com sucesso");
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };
  const RemoveAnswer = async (answer: Answer) => {
    try {
      const response = await http.post(`/answers/remove/`, answer);
      toast.success("Operação realizada com sucesso");
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const readAwnsers = async (questionId: number) => {
    const response = await http.get(`/answers/findByQuestionId/${questionId}`);
    setAnswers(response.data);
  };

  const removeOption = (index: any) => {
    const newAnswers = answers?.filter((_, i) => i !== index);
    setAnswers(newAnswers);
  };

  const handleAddAnswer = () => {
    const answersTmp = answers;
    const newAnswer: Answer = {
      id: 0,
      name: "",
      description: "",
      Question: { id: 0 },
      questionId: 0,
    };

    setAnswers((prevAnswers) => [...(prevAnswers || []), newAnswer]);
  };

  const createCategory = async () => {
    try {
      const response = await http.post(
        `/simple-entity/createQuestionCategory/` + newCategory
      );
      const _questioncategory = await http.get(
        `/simple-entity/questioncategory`
      );
      setquestioncategory(_questioncategory.data);
      setNewCategory("");
      toast.success("Operação realizada com sucesso");
      setcategoryOpen(false);
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const createAnswer = async (answer: Answer) => {
    try {
      answer.questionId = (selectedQuestion as any)?.id;
      const response = await http.post(`/answers/create/`, answer);
      toast.success("Operação realizada com sucesso");
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      values.answers = answers;
      await http.post(
        values.id > 0 ? `/questions/update` : `/questions/create`,
        values
      );
      handleClose();
      navigate("/tools/edit", { state: state });
      window.location.reload();
      toast.success("Operação realizada com sucesso");
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _questiontype = await http.get(`/simple-entity/questiontypes`);
        setQuestiontype(_questiontype.data);
        const _questioncategory = await http.get(
          `/simple-entity/questioncategory`
        );
        setquestioncategory(_questioncategory.data);
        const _questions = await http.get(`/questions/${state}`);
        setRows(_questions.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <OlogaPageHeader
        title="Actualizar Ferramenta"
        items={[{ label: "Ferramentas", url: "/tools/search" }]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ToolForm
                tool={{
                  id: state,
                  name: "",
                  description: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    style={{ marginBottom: "10px" }}
                  >
                    Criar Pergunta
                  </Button>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Formik
                      initialValues={question}
                      validationSchema={toolSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ handleSubmit, status, errors }) => (
                        <Card>
                          <Modal
                            open={categoryOpen}
                            onClose={categoryHandleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Card>
                                <CardContent>
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      createCategory();
                                    }}
                                  >
                                    <Typography variant="h6" gutterBottom>
                                      Criar Nova Categoria
                                    </Typography>
                                    <OlogaTextfield
                                      value={newCategory}
                                      onChange={handleNewCategoryChange}
                                      name="Nome da Categoria"
                                      label="Nome da Categoria"
                                    />
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      style={{ marginTop: "10px" }}
                                    >
                                      Criar Categoria
                                    </Button>
                                  </form>
                                </CardContent>
                              </Card>
                            </Box>
                          </Modal>
                          <CardContent>
                            <form onSubmit={handleSubmit}>
                              <Typography variant="h6" gutterBottom>
                                Criar Pergunta
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <OlogaTextfield name="name" label="Nome" />
                                </Grid>
                                <Grid item xs={6}>
                                  <OlogaTextfield
                                    name="description"
                                    label="Descrição"
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <OlogaSelect
                                      name="categoryId"
                                      options={questioncategory}
                                      label="Categoria"
                                      setValue={handleQuestioncategoryChange}
                                    />
                                    <Button
                                      variant="contained"
                                      onClick={categoryHandleOpen}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Nova Categoria
                                    </Button>
                                  </div>
                                </Grid>
                                <Grid item md={6}>
                                  <OlogaSelect
                                    name="typeId"
                                    options={questiontype}
                                    label="Tipo"
                                    setValue={handleQuestiontypeChange}
                                  />
                                </Grid>
                              </Grid>
                              <>
                                <div>
                                  {answers?.map((option, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <OlogaTextfield
                                        value={(option as any).description}
                                        onChange={(event) =>
                                          handleOptionChange(index, event)
                                        }
                                        name={`Resposta ${index + 1}`}
                                        label={`Resposta ${index + 1}`}
                                      />
                                      <Button
                                        variant="contained"
                                        onClick={() => removeOption(index)}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Remover Resposta
                                      </Button>
                                    </div>
                                  ))}
                                </div>
                              </>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  style={{ marginBottom: "10px" }}
                                >
                                  Salvar
                                </Button>
                              </div>
                              <Grid item xs={6}>
                                <Button
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => {
                                    handleAddAnswer();
                                  }}
                                  sx={{ mb: 2 }}
                                >
                                  Adicionar Resposta
                                </Button>
                              </Grid>
                            </form>
                          </CardContent>
                        </Card>
                      )}
                    </Formik>
                  </Box>
                </Modal>
              </div>
              <TableContainer component={Paper}>
                <Typography
                  variant="h6"
                  gutterBottom
                  marginBottom={5}
                  marginLeft={4}
                >
                  Lista de Perguntas
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.assessment_id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => handleOpenEditQuestion(row)}
                            style={{ marginBottom: "10px" }}
                          >
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <OlogaBackButton />
    </>
  );
}

export default ToolUpdate;
