import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent, Button } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../../utils/axios";
import Table from "@mui/material/Table";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { Assessment, Tool } from "../form_builder/data_mapping";
import BackButton from "../../components/Buttons/BackButton";
import OlogaDataTableNormal from "../../components/tables/OlogaDataTableNormal";
import { GridColDef } from "@mui/x-data-grid";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { format, parse } from "date-fns";

const Card = styled(MuiCard)(spacing);

interface RowInterface {
  name: string;
  description: string;
  id: number;
  status: string;
  assessmentId: any;
}

const RiskAssessmentPage: React.FC = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Candidato",
      flex: 3,
      editable: false,
      renderCell: (params) => (
        <a
          onClick={() => {
            navigate(`/tools/form`, {
              state: {
                returnUrl: "/candidates/create",
                dto: {
                  id: (params.row as any).id,
                  toolId: (params.row as any).tool.id,
                  assessmentId: (params.row as any).assessment.id,
                  candidateId: (params.row as any).candidate.id,
                  toolDesc: (params.row as any).assessment.name,
                  toolCode: (params.row as any).assessment.name,
                  candidateName: (params.row as any).name,
                  assessmentDate: (params.row as any).assessmentDate,
                },
              },
            });
          }}
          style={{
            textDecoration: "none",
            color: "#ec1c24", // Optional: Adjust color for visibility
          }}
        >
          {params.row.name}
        </a>
      ),
    },
    {
      field: "assessmentDate",
      headerName: "Data de Avaliação",
      flex: 3,
      editable: false,
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 1,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <>
          <Button
            size="small"
            variant="outlined"
            startIcon={<VisibilityOutlinedIcon />}
            onClick={() => {
              navigate(`/tools/form`, {
                state: {
                  returnUrl: "/candidates/create",
                  dto: {
                    id: (params.row as any).id,
                    toolId: (params.row as any).tool.id,
                    assessmentId: (params.row as any).assessment.id,
                    candidateId: (params.row as any).candidate.id,
                    toolDescription: (tool as any).description,
                    toolName: (tool as any).name,
                    candidateName: (params.row as any).name,
                    assessmentDate: (params.row as any).assessmentDate,
                  },
                },
              });
            }}
          >
            Editar
          </Button>
        </>,
      ],
    },
  ];

  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [entitytype, setEntitytype] = useState([]);
  const [tool, setTool] = useState([]);
  const [rows, setRows] = React.useState<RowInterface[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const toolRsp = await http.get(`/tools/name/RiskAssessment`);
        setTool(toolRsp.data);

        const _entitytype = await http.get(`/simple-entity/entity-types`);
        setEntitytype(_entitytype.data);

        const result = await http.get(`/assessments/assessmentscores`);
        setRows(result?.data as RowInterface[]);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <OlogaPageHeader
        title="Avaliação de Risco"
        buttonLabel="Adicionar Avaliação de Risco"
        onClick={() => {
          navigate(`/tools/form`, {
            state: {
              dto: {
                assessmentId: 0,
                toolName: (tool as any).name,
                toolId: (tool as any).id,
                toolDescription: (tool as any).description,
                returnUrl: "/candidates/create",
              },
            },
          });
        }}
      />

      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid
              url={`/assessments/assessmentscores`}
              columns={columns}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskAssessmentPage;
function dayjs(value: any) {
  throw new Error("Function not implemented.");
}
