import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/PageLayout";
import SimpleFormLayout from "./layouts/SimpleForm";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import Users from "./pages/auth/Users";
import Profile from "./pages/auth/Profile";
import CreateUser from "./pages/auth/CreateUser";
import UpdateUser from "./pages/auth/UpdateUser";
import SettingsForm from "./pages/settings/SettingsForm";
import SettingsList from "./pages/settings/SettingsList";
import SignOut from "./components/auth/SignOut";
import ImportUsers from "./pages/auth/ImportUsers";
import AssessmentCreate from "./pages/assessments/AssessmentCreate";
import AssessmentUpdate from "./pages/assessments/AssessmentUpdate";
import AssessmentRead from "./pages/assessments/AssessmentRead";
import AssessmentsHistory from "./pages/assessments/AssessmentsHistory";
import ToolUpdate from "./pages/tools/ToolUpdate";
import ToolRead from "./pages/tools/ToolRead";
import ToolCreate from "./pages/tools/ToolCreate";
import FormBuilder from "./pages/form_builder/FormBuilder";
import CandidatePage from "./pages/candidates/CandidatesPage";
import CandidatesPageEdit from "./pages/candidates/CandidatesPageEdit";
import RiskAssessmentPage from "./pages/candidates/RiskAssessmentPage";
import CandidateProgress from "./pages/candidates/CandidateProgress";
import AssessmentsFeedbackPage from "./pages/assessments/AssessmentFeedbackPage";

//Indicators
import PerformanceIndicatorList from "./pages/Indicator/PerformanceIndicatorList";
import PerformanceIndicatorForm from "./pages/Indicator/PerformanceIndicatorForm";
import CandidateCreate from "./pages/candidates/CandidateCreate";
import CandidateUpdate from "./pages/candidates/CandidateUpdate";
import CandidateUser from "./pages/auth/CandidateUser";
import CandidateFeaturesPage from "./pages/candidates/CandidateFeaturesPage";
import AdminFeaturePage from "./pages/assessments/AdminFeaturePage";
import ToolSupplyMapping from "./pages/tools/ToolSupplyMapping";
import ToolDemandMapping from "./pages/tools/ToolDemandMapping";
import ActionPlanPage from "./pages/actionPlan/ActionPlanPage";
import CandidateSignOutCreate from "./pages/candidates/CandidateSignOutCreate";
import MatchmakingTool from "./pages/MatchmakingTool/MatchmakingTool";
import GapAssessment from "./pages/tools/GapAssessment";
import Panel from "./pages/dashboards/Default/Panel";
import AnwersAndQuestions from "./pages/settings/AnwersAndQuestions";
import CandidateDataView from "./pages/candidates/CandidateDataView";
import ProjectLogList from "./pages/tools/ProjectLogList";
import IssueRiskLog from "./pages/tools/IssueRiskLogBeneficiary";
import IssueRiskLogOSS from "./pages/tools/IssueRiskLogOSS";
import GapAssessmentDetails from "./pages/tools/GapAssessments";
import GapAssessments from "./pages/tools/GapAssessments";
import GapAssessmentAdd from "./pages/tools/GapAssessmentAdd";
import ComplainList from "./pages/tools/ComplaintList";
import Dashboard from "./layouts/Dashboard";
import ProjectLogDetails from "./pages/tools/ProjectLogListDetails";
import LogCandidateOss from "./pages/tools/LogCandidateOss";
import IssueRiskLogOSSForm from "./pages/tools/IssueRiskLogOSSForm";
import IssueRiskLogBeneficiaryForm from "./pages/tools/IssueRiskLogBeneficiaryForm";
import GapAssessmentEdit from "./pages/tools/GapAssessmentEdit";

import ComplaintListForm from "./pages/tools/ComplaintListForm";
import ComplaintListEdit from "./pages/tools/ComplaintListEdit";
import ComplaintList from "./pages/tools/ComplaintList";
import ProjectLogEdit from "./pages/tools/ProjectLogEdit";
import ActionPlanForm from "./pages/actionPlan/ActionPlanForm";
import CandidateSelection from "./pages/tools/CandidateSelection";
// Dashboard components

const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "assessments",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <AssessmentCreate />,
      },
      {
        path: "search",
        element: <AssessmentRead />,
      },
      {
        path: "edit",
        element: <AssessmentUpdate />,
        param: "AssessmentId/:candidateId?",
      },
      {
        path: "assessmentsHistory",
        element: <AssessmentsHistory />,
        param: "AssessmentId/:candidateId?",
      },
    ],
  },
  {
    path: "candidates",
    element: <DashboardLayout />,
    children: [
      {
        path: "search",
        element: <CandidatePage showTitle={true} />,
      },
      {
        path: "panel",
        element: <Panel />,
      },
      {
        path: "edit",
        element: <CandidatesPageEdit />,
        param: "AssessmentId",
      },
      {
        path: "create",
        element: <RiskAssessmentPage />,
      },
      {
        path: "progress",
        element: <CandidateProgress />,
      },
      {
        path: "info-create",
        element: <CandidateCreate />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
      {
        path: "view-dialog",
        element: <CandidateDataView />,
      },
    ],
  },
  {
    path: "tools",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <ToolCreate />,
      },
      {
        path: "search",
        element: <ToolRead />,
      },
      {
        path: "edit",
        element: <ToolUpdate />,
        param: "ToolId",
      },
      {
        path: "form",
        element: <FormBuilder />,
      },
    ],
  },
  {
    path: "feedback",
    element: <DashboardLayout />,
    children: [
      {
        path: "view",
        element: <AssessmentsFeedbackPage />,
      },
    ],
  },
  {
    path: "Indicators",
    element: <DashboardLayout />,
    children: [
      {
        path: "view",
        element: <Dashboard />,
      },
      {
        path: "search",
        element: <PerformanceIndicatorList />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <SettingsForm />,
      },
      {
        path: "search",
        element: <SettingsList />,
      },
      {
        path: "edit",
        element: <SettingsForm />,
        param: "settingId",
      },
      {
        path: "answers-and-questions",
        element: <AnwersAndQuestions />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "import-users",
        element: <ImportUsers />,
      },
      {
        path: "add-user",
        element: <CreateUser />,
      },
      {
        path: "edit-user",
        element: <UpdateUser />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "logout",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SignOut />,
      },
    ],
  },
  {
    path: "tool",
    element: <DashboardLayout />,
    children: [
      {
        path: "matchmaking",
        element: <MatchmakingTool />,
      },
    ],
  },
  {
    path: "form",
    element: <SimpleFormLayout />,
    children: [
      {
        path: "info-create",
        element: <CandidateSignOutCreate />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "signup",
        element: <CandidateUser />,
      },
      {
        path: "info-create",
        element: <CandidateSignOutCreate />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "assessment-candidate",
    element: <DashboardLayout />,
    children: [
      {
        path: "options",
        element: <CandidateFeaturesPage />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
      {
        path: "search",
        element: <CandidatePage showTitle={true} />,
      },
    ],
  },
  {
    path: "assessment-admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "options",
        element: <AdminFeaturePage />,
      },
      {
        path: "info-Update",
        element: <CandidateUpdate />,
      },
      {
        path: "search",
        element: <CandidatePage showTitle={true} />,
      },
      {
        path: "supply-mapping",
        element: <ToolSupplyMapping />,
      },
      {
        path: "demand-mapping",
        element: <ToolDemandMapping />,
      },
      {
        path: "qualification",
        element: <CandidateProgress />,
      },
      {
        path: "action-plan",
        element: <ActionPlanPage />,
      },
      {
        path: "plan-candidate",
        element: <LogCandidateOss type={"ACTION_PLAN"} />,
      },
      {
        path: "action-plan-form",
        element: (
          <ActionPlanForm
            assessmentScoreId={0}
            onClose={() => {}}
            actionPlanDto={null}
          />
        ),
      },
      {
        path: "gap-assessment",
        element: <GapAssessment />,
      },
      {
        path: "project-log",
        element: <ProjectLogList />,
      },
      {
        path: "project-log/details/:id/",
        element: <ProjectLogDetails />,
      },
      {
        path: "project-log/edit/:id/",
        element: <ProjectLogEdit />,
      },
      {
        path: "issue-oss",
        element: <IssueRiskLogOSS />,
      },
      {
        path: "issue-beneficary",
        element: <IssueRiskLog />,
      },
      {
        path: "gap-assessment/gaps",
        element: <GapAssessments />,
      },
      {
        path: "gap-assessment/gaps/add",
        element: <GapAssessmentAdd />,
      },
      {
        path: "gap-assessment/gaps/edit",
        element: <GapAssessmentEdit />,
      },
      {
        path: "log-oss",
        element: <IssueRiskLogOSS />,
      },
      {
        path: "issue-oss-form",
        element: <IssueRiskLogOSSForm />,
      },
      {
        path: "log-beneficiary",
        element: <LogCandidateOss type={"ISSUE_RISK_BENEFICIARY"} />,
      },
      {
        path: "issue-beneficary-form",
        element: <IssueRiskLogBeneficiaryForm />,
      },
      {
        path: "complaintlist",
        element: <ComplaintList />,
      },
      {
        path: "complaintlist/edit",
        element: <ComplaintListEdit />,
      },
      {
        path: "candidateselection",
        element: <CandidateSelection />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
