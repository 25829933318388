import styled from "@emotion/styled";
import { ReactComponent as ActionPlan } from "../assets/actionplan.svg";
import { ReactComponent as Demand } from "../assets/demand.svg";
import { ReactComponent as FormCandidate } from "../assets/form_candidate.svg";
import { ReactComponent as GapAssessment } from "../assets/gapassessment.svg";
import { ReactComponent as IssuesOss } from "../assets/issuesoss.svg";
import { ReactComponent as LegalCompliance } from "../assets/legalandcompliance.svg";
import { ReactComponent as ListSelection } from "../assets/listpreselectionbenf.svg";
import { ReactComponent as MappingPartner } from "../assets/mappingpartner.svg";
import { ReactComponent as Matchmaking } from "../assets/matchmaking.svg";
import { ReactComponent as PrioritizationBenef } from "../assets/prioritizationbeneficiary.svg";
import { ReactComponent as ProjectsLogs } from "../assets/projectslogs.svg";
import { ReactComponent as RiskEvaluation } from "../assets/riskevaluation.svg";
import { ReactComponent as Selection } from "../assets/selection.svg";
import { ReactComponent as Supply } from "../assets/supply.svg";

const ActionPlanIcon = styled(ActionPlan)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;

const DemandIcon = styled(Demand)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const FormCandidateIcon = styled(FormCandidate)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const GapAssessmentIcon = styled(GapAssessment)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const IssuesOssIcon = styled(IssuesOss)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const LegalComplianceIcon = styled(LegalCompliance)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const ListSelectionIcon = styled(ListSelection)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const MappingPartnerIcon = styled(MappingPartner)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;

const MatchmakingIcon = styled(Matchmaking)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const PrioritizationBenefIcon = styled(PrioritizationBenef)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const ProjectsLogsIcon = styled(ProjectsLogs)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const RiskEvaluationIcon = styled(RiskEvaluation)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const SelectionIcon = styled(Selection)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;
const SupplyIcon = styled(Supply)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
`;

export {
  ActionPlanIcon,
  DemandIcon,
  FormCandidateIcon,
  GapAssessmentIcon,
  IssuesOssIcon,
  LegalComplianceIcon,
  ListSelectionIcon,
  MappingPartnerIcon,
  MatchmakingIcon,
  PrioritizationBenefIcon,
  ProjectsLogsIcon,
  RiskEvaluationIcon,
  SelectionIcon,
  SupplyIcon,
};
