import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";

interface DemandMappingFormValues {
  id: number;
  candidateId: number;
  activitySector: string;
  analysCategory: string;
  gap: string;
  specificAditionalNeeds: string;
  comments: string;
}
interface dropdownDTO {
  text: string;
  value: number;
}
const GapAssessmentForm: React.FC<{
  onClose: () => void;
  dto: any;
  handleOpenGapList: () => void;
}> = ({ onClose, dto, handleOpenGapList }) => {
  const [initialValues, setInitialValues] = useState<DemandMappingFormValues>({
    id: dto.id ?? "",
    activitySector: dto.activitySector ?? "",
    analysCategory: dto.analysCategory ?? "",
    gap: dto.gap ?? "",
    specificAditionalNeeds: dto.specificAditionalNeeds ?? "",
    comments: dto.comments ?? "",
    candidateId: dto.candidateId,
  });
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);

  useEffect(() => {}, []);
  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
  };
  const handleSubmit = async (
    values: DemandMappingFormValues,
    actions: FormikHelpers<DemandMappingFormValues>
  ) => {
    try {
      values["candidateId"] = initialValues.candidateId;
      const data = { ...values };
      const response = await http.post("/gap-assessment", data);
      if (response.status === 200) {
        toast.success("Operação realizada com sucesso");
        onClose();
        handleOpenGapList();
      }
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    gap: Yup.string().required("Gap is required"),
    analysCategory: Yup.string().required("Analys Category is required"),
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="analysCategory"
                          label="Categoria de Análise"
                          fullWidth
                          value={values.analysCategory}
                          onChange={handleChange}
                          error={
                            touched.analysCategory &&
                            Boolean(errors.analysCategory)
                          }
                          helperText={<ErrorMessage name="analysCategory" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="gap"
                          label="Lacuna"
                          fullWidth
                          value={values.gap}
                          onChange={handleChange}
                          error={touched.gap && Boolean(errors.gap)}
                          helperText={<ErrorMessage name="gap" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="specificAditionalNeeds"
                          label="Necessidades Adicionais Especificas"
                          fullWidth
                          value={values.specificAditionalNeeds}
                          onChange={handleChange}
                          error={
                            touched.specificAditionalNeeds &&
                            Boolean(errors.specificAditionalNeeds)
                          }
                          helperText={
                            <ErrorMessage name="specificAditionalNeeds" />
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="comments"
                          label="Comentários"
                          fullWidth
                          value={values.comments}
                          onChange={handleChange}
                          error={touched.comments && Boolean(errors.comments)}
                          helperText={<ErrorMessage name="district" />}
                        />
                      </Grid>

                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                          <OlogaButtonGroup />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default GapAssessmentForm;
