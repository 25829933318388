import { Card as MuiCard, Button, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const Card = styled(MuiCard)();

export default function SettingsList(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <OlogaPageHeader title="Configurações" />

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/assessments/search")}
          >
            Configuração de Formulários
          </Button>
        </CardContent>
      </Card>
    </>
  );
}
