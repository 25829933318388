import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  max-width: 100%;
  margin: 10px;
  min-height: 100%;
  flex-direction: column;
`;

interface AuthType {
  children?: React.ReactNode;
}

const SimpleForm: React.FC<AuthType> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default SimpleForm;
