import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import UserForm from "./UserForm";

import { ReactComponent as LeftImage } from "../../vendor/leftimage.svg";
import { ReactComponent as Logo } from "../../vendor/logo-hr-blue-tr.svg";

import { spacing } from "@mui/system";
import { ToastContainer } from "react-toastify";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";
import { useState } from "react";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 70%;
  height: auto;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
`;

const LeftImageVar = styled(LeftImage)`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`;

const Container = styled(Box)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1200px;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

const LeftSection = styled(Box)`
  flex: 1;
  padding: 16px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-color: rgb(0, 118, 128);
`;
const RightSection = styled(Box)`
  flex: 1;
  padding: 32px;
`;

function CandidateUser() {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Container>
      <ToastContainer position="top-right" />
      <FormPaper>
        {/* Left Section */}
        <LeftSection>
          <LeftImageVar />
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Através da nossa Ferramenta de Gestão, ajustamos o apoio às
            necessidades específicas do seu negócio, garantindo um processo
            eficiente e transparente. Junte-se a nós e impulsione o crescimento
            da sua empresa!
          </Typography>
        </LeftSection>
        {/* Right Section */}
        <RightSection>
          <Brand />
          <Typography>
            Preencha os campos que seguem, para efectuar o autoregisto.
          </Typography>
          <br />
          <CardContent>
            <UserForm
              user={{
                id: null,
                firstName: "",
                lastName: "",
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
                companyId: "",
                signup: true,
              }}
            />
          </CardContent>
        </RightSection>
      </FormPaper>
    </Container>
  );
}

export default CandidateUser;
