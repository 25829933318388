import React, { useEffect, useState } from "react";
import UserIcon from "@mui/icons-material/PersonOutlined";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import StatusIcon from "@mui/icons-material/CheckCircle";
import BackButton from "../../components/Buttons/BackButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  MenuItem,
  FormControl,
  Typography,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  TableFooter,
  TablePagination,
  TextField,
  Modal,
  Select,
  InputLabel,
  Divider,
  Input,
} from "@mui/material";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";
interface dropdownDTO {
  text: string;
  value: number;
}
interface Candidate {
  id: number;
  candidateReference: string;
  candidateName: string;
  firstName: string;
  companyFoundationYear: number;
  category: string;
  province: string;
  district: string;
  companyType: string;
  activitySector: string;
  numberOfEmployees: string;
  haveAcount: boolean;
  candidatureStatus: string;
}

export type CandidatePageTypeProps = {
  showTitle: boolean;
};

const CandidatePage: React.FC<CandidatePageTypeProps> = ({ showTitle }) => {
  const navigate = useNavigate();
  const { user, isCandidate } = useAuth();
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const rowsPerPage = 10;
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [comment, setComment] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [CANDIDATURE_STATUS, setCANDIDATURE_STATUS] = useState<dropdownDTO[]>(
    []
  );
  const handleOpen = (candidate: any) => {
    setSelectedCandidate(candidate);
    setPassword("");
    setUserName(selectedCandidate?.firstName);
    setPasswordError(false);
    setOpen(true);
  };
  const fetchCandidates = async (pageNumber: number) => {
    setLoading(true);
    try {
      const _response = await http.get(
        `simple-entity/findDropdown/CANDIDATURE_STATUS`
      );

      let statusList = _response.data;

      if (user?.roleName === "ROLE_CANDIDATE") {
        statusList = [
          { text: "Não publicada", value: 1 },
          { text: "Publicada", value: 2 },
          { text: "Cancelada", value: 3 },
        ];
      } else {
        statusList = [
          { text: "Não Aceite", value: 1 },
          { text: "Aceite", value: 2 },
        ];
      }

      setCANDIDATURE_STATUS(statusList);

      const response = await http.get(`/candidate/list`);
      setCandidates(response.data);
    } catch (err: any) {
      setError(err.message);
    }
    setLoading(false);
  };

  const handleOpenStatus = (candidate: any) => {
    setSelectedCandidate(candidate);

    setSelectedStatus(candidate?.candidatureStatus);
    setComment("");
    setOpenStatus(true);
  };

  const handleClose = () => setOpen(false);
  const handleClosestatus = () => setOpenStatus(false);

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-6);
    setPassword(randomPassword);
  };

  const handleUserNameChange = (event: any) => {
    setUserName(event.target.value);
    setUserNameError(event.target.value === "");
  };
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    setPasswordError(event.target.value === "");
  };

  const handleSubmit = async () => {
    try {
      if (password === "") {
        setPasswordError(true);
        return;
      }
      if (userName === "") {
        setUserNameError(true);
        return;
      }
      const accountData = {
        candidateId: selectedCandidate?.id,
        login: userName,
        password: password,
      };
      await http.post(`/users/candidate`, accountData);
      setCandidates((prevCandidates) =>
        prevCandidates.map((item) =>
          item.id === selectedCandidate?.id
            ? { ...item, haveAcount: true }
            : item
        )
      );
      handleClose();
    } catch (error: any) {
      console.log("error:", error);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const handleSubmitcandidatureStatus = async () => {
    try {
      const accountData = {
        candidateId: selectedCandidate?.id,
        status: selectedStatus,
        comment,
      };
      await http.post(`/candidate/status`, accountData);
      toast.success("Operação realizada com sucesso");

      if (user?.roleName === "ROLE_CANDIDATE") {
        handleClosestatus();
        navigate("/dashboard");
      } else {
        fetchCandidates(page);
        handleClosestatus();
      }
    } catch (error: any) {
      console.log("error:", error);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  useEffect(() => {
    fetchCandidates(page);
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };
  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    setSelectedStatus(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <>
      {showTitle && (
        <OlogaPageHeader title="Ferramenta de Pré-selecção de Beneficiários" />
      )}
      {!showTitle && <br />}

      <Box sx={{ padding: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "black" }}>
                {[
                  "Nome",
                  "Ano de Fundação",
                  "Categoria",
                  "Província",
                  "Distrito",
                  "Tipo de Empresa",
                  "Sector de Atividade",
                  "Número de Funcionários",
                  "Estado",
                  "Possui conta no sistema?",
                  "Acções",
                ].map((header, index) => (
                  <TableCell key={index} sx={{ color: "white" }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates.map((candidate) => (
                <TableRow key={candidate.id}>
                  <TableCell>{candidate?.candidateName}</TableCell>
                  <TableCell>{candidate?.companyFoundationYear}</TableCell>
                  <TableCell>{candidate?.category}</TableCell>
                  <TableCell>{candidate?.province}</TableCell>
                  <TableCell>{candidate?.district}</TableCell>
                  <TableCell>{candidate?.companyType}</TableCell>
                  <TableCell>{candidate?.activitySector}</TableCell>
                  <TableCell>{candidate?.numberOfEmployees}</TableCell>
                  <TableCell>{candidate?.candidatureStatus}</TableCell>
                  <TableCell>
                    {candidate?.haveAcount == null || !candidate?.haveAcount
                      ? "Não"
                      : "Sim"}
                  </TableCell>
                  <TableCell style={{ alignItems: "center" }}>
                    <div className="row" style={{ display: "flex" }}>
                      {user?.roleName === "ROLE_ADMIN" && (
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<VisibilityOutlinedIcon />}
                          onClick={() => {
                            navigate(`/candidates/view-dialog`, {
                              state: { id: candidate?.id },
                            });
                          }}
                        >
                          Ver {}
                        </Button>
                      )}
                      {user?.roleName !== "ROLE_ADMIN" &&
                        candidate?.candidatureStatus !== "Aceite" &&
                        candidate?.candidatureStatus !== "Não Aceite" && (
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => {
                              navigate(`/candidates/info-Update`, {
                                state: { id: candidate?.id },
                              });
                            }}
                          >
                            Ver {}
                          </Button>
                        )}
                      {user?.roleName === "ROLE_ADMIN" && (
                        <Button
                          style={{ marginLeft: "4px" }}
                          size="small"
                          variant="outlined"
                          startIcon={<StatusIcon />}
                          onClick={() => handleOpenStatus(candidate)}
                        >
                          Estado
                        </Button>
                      )}
                      {user?.roleName !== "ROLE_ADMIN" &&
                        candidate?.candidatureStatus !== "Aceite" &&
                        candidate?.candidatureStatus !== "Não Aceite" && (
                          <Button
                            style={{ marginLeft: "4px" }}
                            size="small"
                            variant="outlined"
                            startIcon={<StatusIcon />}
                            onClick={() => handleOpenStatus(candidate)}
                          >
                            Estado
                          </Button>
                        )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={6}
                  count={totalPages * rowsPerPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} of ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                Criar Conta
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Login"
                onChange={handleUserNameChange}
                value={userName}
                error={userNameError}
                helperText={
                  userNameError ? "Nome do Utilizador é obrigatório" : ""
                }
              />
              <TextField
                fullWidth
                onChange={handlePasswordChange}
                margin="normal"
                label="Senha"
                value={password}
                error={passwordError}
                helperText={passwordError ? "Senha é obrigatória" : ""}
              />
              <Button
                variant="contained"
                onClick={generatePassword}
                style={{ marginRight: "8px" }}
              >
                Gerar Senha
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Criar Conta
              </Button>
            </Box>
          </Modal>
          <Modal open={openStatus} onClose={handleClosestatus}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                Alterar estado
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="activitySector-label">Estado</InputLabel>
                <Select
                  labelId="activitySector-label"
                  value={selectedStatus}
                  label="Estado"
                  onChange={handleSelectChange("activitySector")}
                >
                  {CANDIDATURE_STATUS.map((status) => (
                    <MenuItem key={status.text} value={status.text}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  label="Comentário"
                  multiline
                  rows={3}
                  value={comment}
                  onChange={handleCommentChange}
                />
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitcandidatureStatus}
              >
                Alterar Estado
              </Button>
            </Box>
          </Modal>
        </TableContainer>
        {showTitle && <OlogaBackButton />}
      </Box>
    </>
  );
};

export default CandidatePage;
