import React, { useEffect, useState } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Card as MuiCard, Grid, Button } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaDataTableNormal from "../../components/tables/OlogaDataTableNormal";
import { toast } from "react-toastify";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../utils/axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";

const CandidateProgress: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [url, setUrl] = useState("/candidate/levels/q");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDown, setOpenDialogDown] = useState(false);
  const [comment, setComment] = useState<string>("");
  const [params, setParams] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Nome Completo",
      flex: 1,
      editable: false,
    },
    {
      field: "nuit",
      headerName: "NUIT",
      flex: 1,
      editable: false,
    },
    {
      field: "category",
      headerName: "Categoria",
      flex: 1,
      editable: false,
    },
    {
      field: "dimension",
      headerName: "Dimensão",
      flex: 1,
      editable: false,
    },
    {
      field: "sector",
      headerName: "Sector de Actividade",
      flex: 1,
      editable: false,
    },
    {
      field: "levelStatus",
      headerName: "Estado de Nivel",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 2,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OlogaButton variant="outlined" label="Subir de Nivel" />}
          onClick={async () => {
            setParams(params);
            setOpenDialog(true);
          }}
          label="Subir de Nivel"
        />,
        <GridActionsCellItem
          icon={<OlogaButton variant="outlined" label="Baixar de Nivel" />}
          onClick={async () => {
            setParams(params);
            setOpenDialogDown(true);
          }}
          label="Baixar de Nivel"
        />,
      ],
    },
  ];

  const updateLevel = async (commentTmp: string) => {
    const response = await axios.post("candidate/levels/upgrade", {
      candidateId: params?.id,
      comment: commentTmp,
    });
    if (response.status === 200) {
      if (response.data === "") {
        toast.error(
          `Não existe um plano de acção para o candidato ${params?.row?.fullName}`
        );
      } else {
        toast.success(
          `Candidato ${params?.row?.fullName} subiu para o nível ${response.data?.level.code}`
        );
        navigate(`/candidates/progress`);
      }
    } else {
      toast.error(
        "Ocorreu o seguinte erro ao realizar a operação: " + response.data
      );
    }
    setOpenDialog(false);
    setComment("");
  };

  const downLevel = async (commentTmp: string) => {
    const response = await axios.post("candidate/levels/downgrade", {
      candidateId: params?.id,
      comment: commentTmp,
    });
    if (response.status === 200) {
      if (response.data === "") {
        toast.error(
          `Não existe um plano de acção para o candidato ${params?.row?.fullName}`
        );
      } else {
        toast.success(
          `Candidato ${params?.row?.fullName} desceu para o nível ${response.data?.level.code}`
        );
        navigate(`/candidates/progress`);
      }
    } else {
      toast.error(
        "Ocorreu o seguinte erro ao realizar a operação: " + response.data
      );
    }
    setOpenDialog(false);
    setComment("");
  };

  useEffect(() => {}, [url]);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Listagem dos Beneficiários Pré-Seleccionados"}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <OlogaButton
              label="Subir Nivel Automaticamente para todos"
              type="button"
              icon={<RefreshIcon />}
              mt={3}
              size="large"
              onClick={async () => {
                const response = await axios.get("candidate/autoUpgradeLevel");
                if (response.status === 200) {
                  toast.success(
                    "Todos os níveis dos candidatos foram actualizados"
                  );
                  navigate(`/candidates/progress`);
                } else {
                  toast.error(
                    "Erro o seguinte erro ao realizar a operação: " +
                      response.data
                  );
                }
              }}
            />
          </Grid>
          {/* </Grid>
              </form>
            )}
          </Formik> */}
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
          <OlogaBackButton />
          <OlogaConfirmDialog
            title="Confirmação!"
            description={`Tem Certeza que deseja subir o nível do Candidato ${params?.row?.fullName}`}
            open={openDialog}
            setOpen={setOpenDialog}
            hasCommentBox={true}
            handleConfirm={(commentValue) => {
              setComment(commentValue); // Set the comment
              updateLevel(commentValue); // Proceed with the level update
            }}
          />
          <OlogaConfirmDialog
            title="Confirmação!"
            description={`Tem Certeza que deseja baixar o nível do Candidato ${params?.row?.fullName}`}
            open={openDialogDown}
            setOpen={setOpenDialogDown}
            hasCommentBox={true}
            handleConfirm={(commentValue) => {
              setComment(commentValue); // Set the comment
              downLevel(commentValue); // Proceed with the level downgrade
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CandidateProgress;
