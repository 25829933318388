import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { format, parse } from "date-fns";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";

interface LogFormValues {
  id: number;
  candidateId: number;
  issueDescription: string;
  beneficiaryName: string;
  potentialImpact: string;
  responsibleIdentification: string;
  responsibleResolution: string;
  resolutionDueDate: any;
  escalationDate: any;
  resolutionDate: any;
  status: string;
  comments: string;
}

interface dropdownDTO {
  text: string;
  value: number;
}

interface Item {
  id: string;
  name: string;
}

const IssueRiskLogBeneficiaryForm: React.FC = () => {
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState<LogFormValues>({
    id: state.id ?? 0,
    comments: state.comments ?? "",
    candidateId: state.candidateId,
    issueDescription: state.issueDescription ?? "",
    beneficiaryName: state.beneficiaryName ?? "",
    potentialImpact: state.potentialImpact ?? "",
    responsibleIdentification: state.responsibleIdentification ?? "",
    responsibleResolution: state.responsibleResolution ?? "",
    resolutionDate: parse(state.resolutionDate, "dd/MM/yyyy", new Date()) ?? "",
    resolutionDueDate:
      parse(state.resolutionDueDate, "dd/MM/yyyy", new Date()) ?? "",
    escalationDate: parse(state.escalationDate, "dd/MM/yyyy", new Date()) ?? "",
    status: state.status ?? "",
  });

  const [impact, setImpact] = useState<dropdownDTO[]>([
    { text: "High", value: 1 },
    { text: "Medium", value: 2 },
    { text: "Low", value: 3 },
  ]);
  const status: Item[] = [
    { id: "OPEN", name: "Aberto" },
    { id: "PROGRESS", name: "Em Progresso" },
    { id: "RESOLVED", name: "Resolvido" },
    { id: "CLOSED", name: "Fechado" },
  ];

  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [impactValue, setImpactValue] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);

  useEffect(() => {}, []);
  const handleSelectChange = (fieldName: any) => (event: any) => {
    const { value } = event.target;
  };

  const handleSelectChangeImpact = (fieldName: any) => (event: any) => {
    const { value } = event.target;
    initialValues.potentialImpact = value;
    setImpactValue(value);
    setInitialValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async (
    values: LogFormValues,
    actions: FormikHelpers<LogFormValues>
  ) => {
    try {
      console.log(values);
      values["candidateId"] = state.candidateId;
      values["potentialImpact"] = impactValue;
      values["resolutionDate"] = format(values.resolutionDate, "dd/MM/yyyy");
      values["resolutionDueDate"] = format(
        values.resolutionDueDate,
        "dd/MM/yyyy"
      );
      values["escalationDate"] = format(values.escalationDate, "dd/MM/yyyy");

      const data = { ...values };
      const response = await http.post("/project-log/beneficiary", data);
      if (response.status === 200) {
        toast.success("Operação realizada com sucesso");
        navigate(`../issue-beneficary`, {
          state: {
            uri: `/project-log/candidate/${state.candidateId}/ISSUE_RISK_BENEFICIARY`,
            candidate: state.candidate,
            issueData: response.data,
            candidateId: state.candidateId,
          },
        });
      }
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    issueDescription: Yup.string().required(" Name is required"),
    // startDate: Yup.date().required("Start Date is required"),
    // endDate: Yup.date()
    //   .required("End Date is required")
    //   .min(Yup.ref("startDate"), "End Date can't be before Start Date"),
  });

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Formulário do Log de Risco ou Preocupação do Beneficiário"}
        // buttonLabel="Adicionar"
        // onClick={handleOpenForm}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                              <InputLabel id="potentialImpact">
                                Potencial Impacto
                              </InputLabel>
                              <Select
                                labelId="potentialImpact"
                                value={initialValues?.potentialImpact}
                                label="Potencial Impacto"
                                onChange={handleSelectChangeImpact(
                                  "potentialImpact"
                                )}
                              >
                                {impact.map((province) => (
                                  <MenuItem
                                    key={province.text}
                                    value={province.text}
                                  >
                                    {province.text}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.potentialImpact && (
                                <FormHelperText error>
                                  Campo obrigatório
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} my={4}>
                            <OlogaSelect
                              name="status"
                              options={status as any}
                              label="Estado"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="issueDescription"
                              label="Descrição do problema"
                              fullWidth
                              value={values.issueDescription}
                              onChange={handleChange}
                              error={
                                touched?.issueDescription &&
                                Boolean(errors.issueDescription)
                              }
                              helperText={
                                <ErrorMessage name="analysCategory" />
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="responsibleIdentification"
                              label="Identificação Responsável"
                              fullWidth
                              value={values.responsibleIdentification}
                              onChange={handleChange}
                              error={
                                touched.responsibleIdentification &&
                                Boolean(errors.responsibleIdentification)
                              }
                              helperText={
                                <ErrorMessage name="responsibleIdentification" />
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <OlogaDatePicker
                              name="resolutionDueDate"
                              label="Data Prevista de Resolução"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="responsibleResolution"
                              label="Responsável da Resolução"
                              fullWidth
                              value={values.responsibleResolution}
                              onChange={handleChange}
                              error={
                                touched.responsibleResolution &&
                                Boolean(errors.responsibleResolution)
                              }
                              helperText={
                                <ErrorMessage name="responsibleResolution" />
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <OlogaDatePicker
                              name="resolutionDate"
                              label="Data de Resolução"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <OlogaDatePicker
                              name="escalationDate"
                              label="Data de Escalonamento"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name="comments"
                              label="Comentarios"
                              fullWidth
                              rows={4}
                              value={values.comments}
                              onChange={handleChange}
                              error={
                                touched.comments && Boolean(errors.comments)
                              }
                              helperText={<ErrorMessage name="comments" />}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <OlogaButtonGroup />
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
          <ToastContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default IssueRiskLogBeneficiaryForm;
