import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Tabs,
  Tab,
  TableHead,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CampanyErrors,
  Candidate,
  Errors,
  FormTypeErrors,
  PersonErrors,
} from "../form_builder/data_mapping";
import http from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface CandidateFormProps {
  id: number;
  candidateDTO: Candidate;
}

const CandidateDataView: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [formtype, setFormType] = useState(5);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeStepTab, setActiveStepTab] = useState<number>(5);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const [candidate, setCandidate] = useState<Candidate>();
  const [loader, setLoader] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState("empresas");
  const [showCompanyInfo, setShowCompanyInfo] = useState(true);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/assessment-admin/search");
  };
  const [steps, setSteps] = useState([
    "Instruções de Preenchimento",
    "Assistência Requerida",
  ]);
  const [errors, setErrors] = useState<Errors>({
    province: true,
    primaryEmail: true,
    category: true,
  });
  const [formTypeErrors, setFormTypeErrors] = useState<FormTypeErrors>({
    desiredAssistance: true,
  });
  const [errorsPerson, setErrorsPerson] = useState<PersonErrors>({
    hasID: true,
    hasTaxNumber: true,
  });
  const [errorsCampany, setErrorsCampany] = useState<CampanyErrors>({
    hasTaxNumberCompany: true,
    hasTaxNumber: true,
    hasID: true,
  });
  const handleChangetab = (event: any, newValue: any) => {
    setActiveStepTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const handleStepChange = (step: number, type: string) => {
    if (step == 2) {
      const newErrors: FormTypeErrors = {
        desiredAssistance: candidate?.desiredAssistance === "",
      };

      if (Object.values(newErrors).some((error) => error)) {
        setFormTypeErrors(newErrors);
        return;
      }
    }
    if (step == 3) {
      const newErrors: Errors = {
        province: candidate?.province === "",
        primaryEmail: candidate?.primaryEmail === "",
        category: candidate?.category === "",
      };

      if (Object.values(newErrors).some((error) => error)) {
        setErrors(newErrors);
        return;
      }
    }
    if (step == 5) {
      if (selectedRequirement === "particulares") {
        const newErrors: PersonErrors = {
          hasID: candidate?.hasID === "",
          hasTaxNumber: candidate?.hasTaxNumber === "",
        };

        if (Object.values(newErrors).some((error) => error)) {
          setErrorsPerson(newErrors);
          return;
        }
      }

      if (selectedRequirement === "empresas") {
        const newErrors: CampanyErrors = {
          hasTaxNumber: candidate?.hasTaxNumber === "",
          hasTaxNumberCompany: candidate?.hasTaxNumberCompany === "",
          hasID: candidate?.hasID === "",
        };

        if (Object.values(newErrors).some((error) => error)) {
          setErrorsCampany(newErrors);
          return;
        }
      }
    }
    if (step == 3) {
      if (!showCompanyInfo) {
        if (type == "next") {
          step++;
        } else {
          step--;
        }
      }
    }
    setActiveStep(step);
    setActiveStepTab(0);
  };

  useEffect(() => {
    try {
      const fetchCandidateInfo = async () => {
        const result = await http.get(`/candidate/` + state.id);

        console.log(result.data);
        setCandidate(result.data);
        setActiveStepTab(0);
        setActiveStep(6);
        if (result.status == 200) {
          setOpenDialog(true);
        }
      };
      fetchCandidateInfo();
    } catch (e) {
      console.log(e);
    }
  }, []);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Resumo dos Dados</DialogTitle>
            <DialogContent dividers>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={activeStepTab}
                    onChange={handleChangetab}
                    aria-label="basic tabs example"
                  >
                    {activeStep >= 1 && (
                      <Tab label="Assistência Requerida" {...a11yProps(0)} />
                    )}
                    {activeStep >= 2 && (
                      <Tab
                        label="Informações Gerais do Candidato"
                        {...a11yProps(1)}
                      />
                    )}
                    {activeStep >= 3 && (
                      <Tab label="Perfil da Empresa" {...a11yProps(2)} />
                    )}
                    {activeStep >= 4 && (
                      <Tab label="Informação Legal" {...a11yProps(3)} />
                    )}
                    {activeStep >= 5 && (
                      <Tab
                        label="Informação sobre a gestão da empresa do candidato"
                        {...a11yProps(4)}
                      />
                    )}
                  </Tabs>
                </Box>
                <CustomTabPanel value={activeStepTab} index={0}>
                  <Box display="flex" justifyContent="center" mb={2}>
                    {/*
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleStepChange(1, "next");
                          handleCloseDialog();
                        }}
                        sx={{ mb: 2 }}
                      >
                        Alterar Dados da Seção : Assistência Requerida
                      </Button>
                    */}
                  </Box>
                  <TabPanel value={activeStepTab} index={0}>
                    <Box mt={2}>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                          Assistência Requerida
                        </Typography>
                        <Table
                          sx={{
                            border: "1px solid #ddd",
                            "& th, & td": {
                              border: "1px solid #ddd",
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <strong>Assistência Desejada:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.desiredAssistance || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Assistência Adicional:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.additionalAssistance || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Detalhes da Assistência:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.assistanceDetails || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Finalidade da Assistência:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.assistancePurpose || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>
                                  Montante Requerido (se aplicável):
                                </strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.requestedInvestmentAmount || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>
                                  Criação de Novos Postos de Trabalho:
                                </strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.createsNewJobs ? "Sim" : "Não"}
                              </TableCell>
                            </TableRow>
                            {candidate?.createsNewJobs && (
                              <TableRow>
                                <TableCell>
                                  <strong>
                                    Estimativa Anual de Criação de Postos:
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  {candidate?.estimatedAnnualJobCreation || ""}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>
                                <strong>Benefício Anterior:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.previouslyBenefited ? "Sim" : "Não"}
                              </TableCell>
                            </TableRow>
                            {candidate?.previouslyBenefited && (
                              <TableRow>
                                <TableCell>
                                  <strong>
                                    Detalhes da Assistência Anterior:
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  {candidate?.previousAssistanceDetails || ""}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>
                                <strong>Aspectos Adicionais:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.additionalAspects || ""}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </TabPanel>
                </CustomTabPanel>
                <CustomTabPanel value={activeStepTab} index={1}>
                  {/*
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleStepChange(2, "next");
                        handleCloseDialog();
                      }}
                      sx={{ mb: 2 }}
                    >
                      Alterar Dados da Seção : Informações Gerais do Candidato
                    </Button>
                  </Box>
                  */}
                  <TabPanel value={activeStepTab} index={1}>
                    <Box mt={2}>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                          Informações Gerais do Candidato
                        </Typography>

                        <Table
                          sx={{
                            border: "1px solid #ddd",
                            "& th, & td": {
                              border: "1px solid #ddd",
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <strong>Categoria do Candidato:</strong>
                              </TableCell>
                              <TableCell>{candidate?.category || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Nome:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.firstName || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Sobrenome:</strong>
                              </TableCell>
                              <TableCell>{candidate?.surname || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Ano de Fundação (se aplicável):</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.companyFoundationYear || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Pessoa de Contato:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.personName || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Contato Principal:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.personPrimaryContact || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Pessoa de Contato Alternativo:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.personAlternateContact || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Fax/Telefax:</strong>
                              </TableCell>
                              <TableCell>{candidate?.fax || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>E-mail Principal:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.primaryEmail || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Email Alternativo:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.alternateEmail || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Website da Empresa:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.companyWebsite || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Província:</strong>
                              </TableCell>
                              <TableCell>{candidate?.province || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Distrito:</strong>
                              </TableCell>
                              <TableCell>{candidate?.district || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>Endereço Físico:</strong>
                              </TableCell>
                              <TableCell>
                                {candidate?.physicalAddress || ""}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </TabPanel>
                </CustomTabPanel>
                <CustomTabPanel value={activeStepTab} index={2}>
                  {/*
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleStepChange(3, "next");
                        handleCloseDialog();
                      }}
                      sx={{ mb: 2 }}
                    >
                      Alterar Dados da Seção :Perfil da Empresa
                    </Button>
                  </Box>
                  */}
                  <TabPanel value={activeStepTab} index={2}>
                    <Box p={3}>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                          Perfil da Empresa
                        </Typography>

                        <Table
                          sx={{
                            border: "1px solid #ddd",
                            "& th, & td": {
                              border: "1px solid #ddd",
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">
                                Sector de Actividade
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.activitySector}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Subsector de Atividade
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.subActivitySector}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Número de Funcionários
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.numberOfEmployees}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Dimensão do Candidato
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.size}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Descrição das Atividades
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.activitiesDescription}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Antiguidade no Mercado
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.marketExperienceYears}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Tipo de Sociedade
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.companyType}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">Accionistas</TableCell>
                              <TableCell align="right">
                                {candidate?.shareholders.map(
                                  (shareholder, index) => (
                                    <Box key={index} mb={2}>
                                      <Typography variant="body2">
                                        Nome: {shareholder.name}
                                      </Typography>
                                      <Typography variant="body2">
                                        Participação:{" "}
                                        {shareholder.participation}
                                      </Typography>
                                      <Typography variant="body2">
                                        Nacionalidade: {shareholder.nationality}
                                      </Typography>
                                    </Box>
                                  )
                                )}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left">
                                Equipe Executiva
                              </TableCell>
                              <TableCell align="right">
                                {candidate?.executiveTeam.map(
                                  (executive, index) => (
                                    <Box key={index} mb={2}>
                                      <Typography variant="body2">
                                        Nome: {executive.name}
                                      </Typography>
                                      <Typography variant="body2">
                                        Função: {executive.position}
                                      </Typography>
                                      <Typography variant="body2">
                                        Anos de Experiência:{" "}
                                        {executive.industryExperienceYears}
                                      </Typography>
                                      <Typography variant="body2">
                                        Descrição da Experiência:{" "}
                                        {
                                          executive.industryExperienceDescription
                                        }
                                      </Typography>
                                    </Box>
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </TabPanel>
                </CustomTabPanel>
                <CustomTabPanel value={activeStepTab} index={3}>
                  {/*
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleStepChange(4, "next");
                        handleCloseDialog();
                      }}
                      sx={{ mb: 2 }}
                    >
                      Alterar Dados da Seção: Informação Legal
                    </Button>
                  </Box>
                    */}
                  <TabPanel value={activeStepTab} index={3}>
                    <Box p={3}>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                          Informação Legal
                        </Typography>

                        <Table
                          sx={{
                            border: "1px solid #ddd",
                            "& th, & td": {
                              border: "1px solid #ddd",
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>Certidão Comercial</TableCell>
                              <TableCell>
                                {candidate?.hasCommercialCertificate}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Certidão de Quitação do INSS
                              </TableCell>
                              <TableCell>
                                {candidate?.hasINSSCertificate}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>NUIT Empresarial</TableCell>
                              <TableCell>
                                {candidate?.hasTaxNumberCompany}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Certidão de Quitação do Tribunal
                              </TableCell>
                              <TableCell>
                                {candidate?.hasCourtClearanceCertificate}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Alvara</TableCell>
                              <TableCell>{candidate?.alvara}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Certidão de Quitação das Finanças
                              </TableCell>
                              <TableCell>
                                {candidate?.hasFinanceClearanceCertificate}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>BI</TableCell>
                              <TableCell>{candidate?.hasID}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Comprovativo de Residência</TableCell>
                              <TableCell>
                                {candidate?.hasResidenceProof}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>NUIT Pessoal</TableCell>
                              <TableCell>{candidate?.hasTaxNumber}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Declaração de Rendimentos</TableCell>
                              <TableCell>
                                {candidate?.incomeStatement}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Registo Criminal</TableCell>
                              <TableCell>
                                {candidate?.hasCriminalRecord}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </TabPanel>
                </CustomTabPanel>
                <CustomTabPanel value={activeStepTab} index={4}>
                  {/*
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleStepChange(5, "next");
                        handleCloseDialog();
                      }}
                      sx={{ mb: 2 }}
                    >
                      Alterar Dados da Seção: Informação sobre a gestão da
                      empresa do candidato
                    </Button>
                  </Box>
                    */}
                  <TabPanel value={activeStepTab} index={4}>
                    <Box p={3}>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                          Informação sobre a gestão da empresa do candidato
                        </Typography>

                        <Table
                          sx={{
                            border: "1px solid #ddd",
                            "& th, & td": {
                              border: "1px solid #ddd",
                            },
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Categoria</TableCell>
                              <TableCell>Informação</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Footprint e Capacidade Técnica
                                </Typography>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui representação na
                                região onde pretende prestar os serviços?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasRegionalRepresentation
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor indique.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .regionalRepresentationDetails
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato pretende expandir as suas
                                atividades nos próximos anos para outras
                                geografias?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .plansToExpandGeographies
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se sim, por favor indique e justifique-se.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .expansionDetails
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato fornece os seus Bens e
                                Serviços para outras regiões em que não tenha
                                sucursais (se aplicável)?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .providesServicesOutsideRegions
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor indique.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .serviceOutsideRegionsDetails
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidado possui processos de HSE
                                (Saúde e Segurança no Trabalho) instituído na
                                sua empresa?
                              </TableCell>
                              <TableCell>
                                {candidate?.candidateManagement.hasHSEProcesses}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor descreva.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hseProcessesDetails
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                Existe algum tipo de manutenção (programada ou
                                corretiva) executada pela Empresa do Candidato?
                                (Ex: Equipamentos)
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .performsMaintenance
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor descreva.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .maintenanceDetails
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui alguma Unidade de
                                Controlo de Qualidade na sua estrutura
                                organizacional?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasQualityControlUnit
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor descreva.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .qualityControlUnitDetails
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui Competências
                                Técnicas para responder a demanda do seu sector?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasTechnicalCompetence
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Se sim, por favor descreva.</TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .technicalCompetenceDetails
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Capacidade de Gestão do Negócio
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui um Código de Ética
                                em que actua em conformidade? Se sim, anexe o
                                comprovativo.
                              </TableCell>
                              <TableCell>
                                {candidate?.candidateManagement.hasEthicsCode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .ethicsCodeJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Estrutura Orgânica
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui uma Estrutura
                                Orgânica que evidencie segregação de funções?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasOrganizationalStructure
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .organizationalStructureJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Processos e Procedimentos
                                </Typography>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui processos e
                                procedimentos devidamente definidos de acordo
                                com as suas actividades?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasDefinedProcesses
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .definedProcessesJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Planeamento de Compras
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui algum planeamento
                                para realizar as suas compras?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasPurchasePlanning
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .purchasePlanningJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Recursos Humanos
                                </Typography>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui as suas
                                funções/departamentos estruturados de acordo com
                                o seu tipo de negócio?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasStructuredFunctions
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .structuredFunctionsJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui os recursos
                                adequados em número e qualidade para desempenhar
                                as suas actividades?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasAdequateResources
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .adequateResourcesJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                Existe uma distribuição equitiva na igualdade de
                                género, na Empresa do Beneficiário?
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasGenderEquality
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .genderEqualityJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui um elevado nível
                                de rotatividade?
                              </TableCell>
                              <TableCell>
                                {candidate?.candidateManagement.hasHighTurnover}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se sim, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .highTurnoverJustification
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Informação Financeira
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Por favor, indique o Regime de Tributação da
                                Empresa do Candidato?
                              </TableCell>
                              <TableCell>
                                {candidate?.candidateManagement.taxRegime}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Qual é a data de reporte mais recente das
                                Demonstrações Financeiras da Empresa do
                                Candidato?*
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .latestFinancialReportDate
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Volume de Negócios Anual da Empresa do Candidato
                              </TableCell>
                              <TableCell>
                                {candidate?.candidateManagement.annualTurnover}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                As Demonstrações da Empresa são Auditadas por um
                                auditor Independente? Se sim, por favor indique
                                a frequência.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .financialStatementsAudited
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Frequência de produção das Demonstrações
                                Financeiras da Empresa do Candidato
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .financialStatementsFrequency
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Por favor, forneça as Demonstrações Financeiras
                                dos últimos 3 anos Financeiros (se aplicável)
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .financialStatementsLast3Years
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography variant="h6">
                                  Sistemas e Equipamentos
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui equipamentos
                                adequados para desempenhar as suas actividades?*
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasAdequateEquipment
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .adequateEquipmentJustification
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui sistemas adequados
                                para desempenhar as suas actividades?*
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasAdequateSystems
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .adequateInfrastructureJustification
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                A Empresa do Candidato possui infraestruturas
                                adequadas às suas actividades?* Se sim, por
                                favor forneça Imagens das suas instalações
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .hasAdequateInfrastructure
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Se não, por favor justifique.
                              </TableCell>
                              <TableCell>
                                {
                                  candidate?.candidateManagement
                                    .adequateSystemsJustification
                                }
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </TabPanel>
                </CustomTabPanel>
              </Box>
            </DialogContent>
            {loader ? (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            ) : (
              ""
            )}
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Fechar
              </Button>
              {/*
                 loader ? ( ""
                ) : (
                 <Button onClick={() => handleSubmit()} color="primary">
                  Confirmar e Submeter
                 </Button>
                )
                */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CandidateDataView;
