import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  CardContent,
  Button,
  Modal,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import { toast } from "react-toastify";
import BackButton from "../../components/Buttons/BackButton";
import ActionPlanForm from "../actionPlan/ActionPlanForm";
import http from "../../utils/axios";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import SearchIcon from "@mui/icons-material/Search";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";

const Card = styled(MuiCard)(spacing);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const ActionPlanPage: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackGapModal, setOpenFeedbackGapModal] = useState(false);
  const [openActionPlanModal, setOpenActionPlanModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<any>(0);
  const [actiomPlanDto, setActionPlanDto] = useState<any>();
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState(state.uri);
  const [url, setUrl] = useState("/candidate");

  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenActionPlanModal = () => setOpenActionPlanModal(true);
  const handleCloseActionPlanModal = () => setOpenActionPlanModal(false);
  const handleOpenFeedbackGapModal = () => setOpenFeedbackGapModal(true);
  const handleCloseFeedbackGapModal = () => setOpenFeedbackGapModal(false);

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");

  const Gapcolumns: GridColDef[] = [
    {
      field: "analysCategory",
      headerName: "Categorias de Analise",
      flex: 2,
      editable: false,
    },
    {
      field: "gap",
      headerName: "Gap",
      flex: 3,
      editable: false,
    },

    {
      field: "specificAditionalNeeds",
      headerName: "Necessidades Adicionais Especificas",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentarios",
      flex: 2,
      editable: false,
    },
    {
      field: "status",
      headerName: "Possui Plano?",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 4,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <>
          {params.row.status == "Não" ? (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                // console.log(params.row);
                // setActionPlanDto(params.row as any);
                // setAssessmentId(params.id as number);
                // handleOpenActionPlanModal();
                navigate(`../action-plan-form`, {
                  state: {
                    assessmentScoreId: params.id as number,
                    actionPlanDto: params.row as any,
                  },
                });
              }}
            >
              Adicionar
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                // console.log(params.row);
                // setActionPlanDto(params.row as any);
                // setAssessmentId(params.id as number);
                // handleOpenActionPlanModal();
                navigate(`../action-plan-form`, {
                  state: {
                    assessmentScoreId: params.id as number,
                    actionPlanDto: params.row as any,
                  },
                });
              }}
            >
              Alterar
            </Button>
          )}
        </>,
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);

        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <>
      <OlogaPageHeader title="Detalhe do Plano de Acção" />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" mb={4}>
            Beneficiario Selecionado: {state.candidateName}
          </Typography>
          <Card>
            <OlogaDataGrid url={state.uri} columns={Gapcolumns} />
          </Card>
        </Grid>
      </Grid>
      <OlogaBackButton />
    </>
  );
};

export default ActionPlanPage;
