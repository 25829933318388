import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { toast } from "react-toastify";
import { SelectChangeEvent } from "@mui/material";
import http from "../../utils/axios";

interface dropdownDTO {
  text: string;
  value: number;
}

interface IQuestion {
  id: number;
  description: string;
  name: string;
  categoryId: number;
  typeId: number;
  answers?: string[];
}

interface AddQuestionModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void; // Callback após a submissão bem-sucedida
}

const AddQuestionModal: React.FC<AddQuestionModalProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [dropdown_tool, setDropdown_tool] = useState<dropdownDTO[]>([]);
  const [dropdown_type, setDropdown_type] = useState<dropdownDTO[]>([]);
  const [newQuestion, setNewQuestion] = useState<IQuestion>({
    id: 0,
    description: "",
    name: "",
    categoryId: 0,
    typeId: 0,
    answers: [],
  });
  const [newAnswers, setNewAnswers] = useState<string[]>([""]);

  const fetchDropdownData = async () => {
    try {
      const QUESTION_TOOL = await http.get(`setting/questions-tool`);
      setDropdown_tool(QUESTION_TOOL.data);

      const QUESTION_TYPE = await http.get(
        `simple-entity/findDropdown/QUESTION_TYPE`
      );
      setDropdown_type(QUESTION_TYPE.data);
    } catch (error) {
      console.error("Erro ao carregar dados do dropdown:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchDropdownData();
    }
  }, [open]);

  const handleAddAnswer = () => {
    setNewAnswers([...newAnswers, ""]);
  };

  const handleRemoveAnswer = (index: number) => {
    const updatedAnswers = newAnswers.filter((_, i) => i !== index);
    setNewAnswers(updatedAnswers);
  };

  const handleAnswerChange = (index: number, value: string) => {
    const updatedAnswers = [...newAnswers];
    updatedAnswers[index] = value;
    setNewAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    const questionPayload = {
      ...newQuestion,
      answers: newAnswers,
    };

    try {
      await http.post(`questions/addQuestionSetting`, questionPayload);
      toast.success("Operação realizada com sucesso");
      onSuccess(); // Chamar o callback de sucesso para atualizar o componente pai
      onClose(); // Fechar o modal
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  const handleSelectChange =
    (fieldName: keyof typeof newQuestion) =>
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      setNewQuestion({ ...newQuestion, [fieldName]: value });
    };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ width: 800, bgcolor: "white", p: 4, mx: "auto", my: "10%" }}>
        <Typography variant="h6">Adicionar Pergunta e Respostas</Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Detalhes da Pergunta</Typography>
            <TextField
              fullWidth
              label="Nome da Pergunta"
              value={newQuestion.name}
              onChange={(e) =>
                setNewQuestion({ ...newQuestion, name: e.target.value })
              }
              margin="normal"
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Descrição"
              value={newQuestion.description}
              onChange={(e) =>
                setNewQuestion({
                  ...newQuestion,
                  description: e.target.value,
                })
              }
              margin="normal"
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Tipo</InputLabel>
              <Select
                labelId="category-label"
                value={newQuestion.categoryId}
                label="Tipo"
                onChange={handleSelectChange("categoryId")}
              >
                {dropdown_type.map((x) => (
                  <MenuItem key={x.text} value={x.value}>
                    {x.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="type-label">Assessment</InputLabel>
              <Select
                labelId="type-label"
                value={newQuestion.typeId}
                label="Assessment"
                onChange={handleSelectChange("typeId")}
              >
                {dropdown_tool.map((x) => (
                  <MenuItem key={x.text} value={x.value}>
                    {x.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box mt={2}>
              <Button variant="contained" onClick={handleSubmit}>
                Submeter
              </Button>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">Respostas</Typography>

            {newAnswers.map((answer, index) => (
              <Grid
                container
                spacing={1}
                key={index}
                alignItems="center"
                sx={{ mb: 2, mt: 3 }}
              >
                <Grid item xs={10}>
                  <TextField
                    sx={{ mb: 2 }}
                    fullWidth
                    label={`Resposta ${index + 1}`}
                    value={answer}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {index > 0 && (
                    <IconButton onClick={() => handleRemoveAnswer(index)}>
                      <RemoveCircleIcon color="error" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}

            <Button
              startIcon={<AddCircleIcon />}
              onClick={handleAddAnswer}
              sx={{ mb: 2 }}
            >
              Adicionar Resposta
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddQuestionModal;
