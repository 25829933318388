import { Box } from "@mui/material";
import { OlogaButton } from "./OlogaButton";
import { useNavigate } from "react-router-dom";

function OlogaBackButton() {
  const navigate = useNavigate();

  return (
    <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
      <>
        <OlogaButton label={"Voltar"} onClick={() => navigate(-1)} />
      </>
    </Box>
  );
}

export default OlogaBackButton;
